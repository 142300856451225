import React from 'react'

import { FrankieIcon } from 'frankify/src'

import { ProcessResultObject, WorkflowStepResultEnum } from 'entities/entity'
import {
  PROCESS_RESULT_STATUS_MAP,
  SupplementaryDataBase,
} from 'entities/entity/model/entity.model'

type IconConfigType = {
  type?: WorkflowStepResultEnum
}

export const failVariants = [
  WorkflowStepResultEnum.FAIL,
  WorkflowStepResultEnum.NO_MATCH,
  WorkflowStepResultEnum.ERROR,
  WorkflowStepResultEnum.INCOMPLETE,
  WorkflowStepResultEnum.PARTIAL,
]

export const passVariants = [
  WorkflowStepResultEnum.PASS,
  WorkflowStepResultEnum.MATCH,
  WorkflowStepResultEnum.CLEAR,
  WorkflowStepResultEnum.CLEARED,
]

export const reviewVariants = [
  WorkflowStepResultEnum.MISSING_DATA,
  WorkflowStepResultEnum.EXPIRED,
]
export function IconConfig({ type }: IconConfigType) {
  if (type) {
    if (failVariants.includes(type))
      return (
        <FrankieIcon name="mdiCloseCircle" className="text-tertiary-red-700 " />
      )
    if (passVariants.includes(type))
      return (
        <FrankieIcon
          name="mdiCheckCircle"
          className="text-tertiary-green-600"
        />
      )
    if (reviewVariants.includes(type))
      return (
        <FrankieIcon
          name="mdiAlertCircle"
          className="text-tertiary-yellow-300"
        />
      )
    if (type === WorkflowStepResultEnum.UNCHECKED) {
      return (
        <FrankieIcon name="mdiMinusCircle" className="text-tertiary-grey-700" />
      )
    }
    return null
  }
}

export function borderStyleConfig(eventResult?: WorkflowStepResultEnum) {
  if (!eventResult) return 'border-tertiary-grey-200'

  if (failVariants.includes(eventResult)) return 'border-tertiary-red-500'

  if (reviewVariants.includes(eventResult)) return 'border-tertiary-yellow-400'

  if (eventResult === WorkflowStepResultEnum.UNCHECKED)
    return 'border-tertiary-grey-500'

  return 'border-tertiary-grey-200'
}

export function getStatusFromProcessResult<
  T extends SupplementaryDataBase['type'],
>(type: T, processResults?: ProcessResultObject[]): WorkflowStepResultEnum {
  if (!processResults) return WorkflowStepResultEnum.UNCHECKED

  const process = processResults.find(
    p => p.supplementaryData?.type === type,
  ) as ProcessResultObject<T> | undefined

  if (!process || !process.result) return WorkflowStepResultEnum.UNCHECKED
  return PROCESS_RESULT_STATUS_MAP[process.result]
}
