import React, { useEffect, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import {
  FrankieButton,
  FrankieDivider,
  FrankieLoader,
  FrankiePopover,
  FrankieTextField,
} from 'frankify/src'

import { businessInfoConfig } from 'entities/applicant'
import { isAbn, isAcn, useBusinessSearch } from 'entities/organisation'

import { getError } from 'shared/form'
import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../../locale/applicant-business-ownership.en'
import { AssociatePartyFormData } from '../../../model/associate-party.model'
import { applicantAssociatePartiesQa } from '../../../qa/applicant-business-ownership.qa'
import { ABRBusinessSummary } from '../abr-business-summary/abr-business-summary'

export function AssociateNewBusiness() {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const [open, setOpen] = useState(false)
  const [manualEntry, setManualEntry] = useState(false)

  const {
    setValue,
    register,
    watch,
    resetField,
    unregister,
    formState: { errors },
  } = useFormContext<AssociatePartyFormData>()

  const {
    data: businessData,
    isFetching,
    handleSearchValue,
  } = useBusinessSearch({})

  const searchValue = watch('searchValue')
  const isPartyTypeBusiness = watch('partyType') === 'BUSINESS'
  const abnOrAcnValue = watch('abnOrAcn')

  useEffect(() => {
    register('searchValue', {
      required: !manualEntry && isPartyTypeBusiness,
    })

    return () => {
      unregister('searchValue')
    }
  }, [isPartyTypeBusiness, manualEntry, register, unregister])

  useEffect(() => {
    if (searchValue && !manualEntry) {
      const businessInfo = businessData?.find(
        business => business.abn === searchValue,
      )
      setValue('organisationData', {
        ...businessInfoConfig,
        ABNNumber: businessInfo?.abn ?? null,
        ACNNumber: businessInfo?.acn ?? null,
        businessName: businessInfo?.name ?? null,
        businessProfile: 'organisation',
        businessType: businessInfo?.type ?? null,
      })
    }
  }, [businessData, searchValue, setValue, manualEntry])

  useEffect(() => {
    if (abnOrAcnValue && manualEntry) {
      const trimmedValue = abnOrAcnValue.replace(/\s/g, '')

      if (isAbn(abnOrAcnValue)) {
        setValue('organisationData.ABNNumber', trimmedValue)
        setValue('organisationData.ACNNumber', '')
      } else if (isAcn(abnOrAcnValue)) {
        setValue('organisationData.ACNNumber', trimmedValue)
        setValue('organisationData.ABNNumber', '')
      }
    }
  }, [abnOrAcnValue, manualEntry, resetField, setValue])

  return (
    <Show>
      <Show.When isTrue={!manualEntry}>
        <FrankiePopover
          open={open}
          onOpenChange={setOpen}
          initialFocus={-1}
          trigger={
            <FrankieTextField
              type="search"
              className="basis-[32%]"
              isSearchIcon
              placeholder={t('searchBusiness')}
              onChange={e => handleSearchValue(e.target.value)}
              onClick={() => setOpen(true)}
              testId={{ input: applicantAssociatePartiesQa.textField }}
            />
          }
        >
          <div className="bg-mono-white shadow-lg rounded-sm w-[calc(95vw-46px)] max-w-[970px]">
            <Show>
              <Show.When isTrue={isFetching}>
                <FrankieLoader
                  label={t('loading.business')}
                  loading
                  className="text-tertiary-grey-800 text-xs font-semibold min-h-[100px]"
                  size="sm"
                />
              </Show.When>

              <Show.When isTrue={!!businessData?.length}>
                <div className="h-[350px] overflow-y-auto ">
                  <div className="sticky top-0 flex text-tertiary-grey-400 px-2 py-1 border-b border-tertiary-grey-200 bg-mono-white">
                    <p className="basis-3/5">{t('name')}</p>
                    <p className="basis-1/5">{t('abn')}</p>
                    <p className="basis-1/5">{t('entityStatus')}</p>
                  </div>

                  {businessData?.map(({ abn, name, isActive }) => (
                    <FrankieButton
                      noStyles
                      className="flex p-2 hover:bg-primary-50 cursor-pointer text-left w-full"
                      onClick={() => {
                        setValue('searchValue', abn, {
                          shouldValidate: true,
                        })
                        setOpen(false)
                      }}
                    >
                      <p className="basis-3/5">{name}</p>
                      <p className="basis-1/5">{abn}</p>
                      <p className="basis-1/5">
                        {isActive ? t('active') : t('cancelled')}
                      </p>
                    </FrankieButton>
                  ))}
                </div>
              </Show.When>

              <Show.Else>
                <p className="p-4 font-bold">{t('noResults')}</p>
              </Show.Else>
            </Show>

            <FrankieDivider />
            <div className="px-4 py-2">
              <FrankieButton
                size="xs"
                onClick={() => {
                  setOpen(false)
                  setManualEntry(true)
                }}
              >
                {t('searchBusinessNotInList')}
              </FrankieButton>
            </div>
          </div>
        </FrankiePopover>

        <ABRBusinessSummary className="mt-4" searchTerm={searchValue} />
      </Show.When>

      <Show.When isTrue={manualEntry}>
        <FrankieTextField
          label={t('businessName')}
          className="!w-[70%] mb-4"
          {...register('organisationData.businessName', {
            required: manualEntry && isPartyTypeBusiness,
          })}
          error={!!getError('organisationData.businessName', errors)}
        />

        <FrankieTextField
          className="!w-[30%] mb-4"
          label={t('abnOrAcn')}
          type="number"
          {...register('abnOrAcn', {
            validate: value =>
              !value || (!!value && (isAbn(value) || isAcn(value))),
          })}
          error={!!getError('abnOrAcn', errors)}
        />

        <FrankieButton
          intent="subtle"
          className="!ps-0 !pe-1"
          startIcon={{
            name: 'mdiMagnify',
          }}
          onClick={() => setManualEntry(false)}
        >
          {t('backToRegistrySearch')}
        </FrankieButton>
      </Show.When>
    </Show>
  )
}
