import React, { useEffect, useState } from 'react'

import { FrankieButton } from 'frankify/src'

import { GalleryItem } from 'shared/image-gallery'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { Media } from './media'

export type Props = {
  gallery: string[] | GalleryItem[]
  trackingEvents?: {
    zoom?: TrackingEventsTypes
    rotate?: TrackingEventsTypes
    gallery?: TrackingEventsTypes[]
  }
  className?: string
  allowZoom?: boolean
  headerSlot?: (idx: number) => React.ReactNode
  defaultIdx?: number
  onImageChange?: (idx: number) => void
}
export function FrankieUtilityImg({
  className = '',
  allowZoom = true,
  gallery,
  headerSlot,
  trackingEvents,
  defaultIdx = 0,
  onImageChange,
}: Props) {
  const [rotateDeg, setRotateDeg] = useState<number[]>([0])
  const [imgIdx, setImgIdx] = useState(defaultIdx)
  useEffect(() => {
    if (trackingEvents?.gallery?.[imgIdx])
      trackingManager.track(trackingEvents.gallery[imgIdx])
  }, [imgIdx, trackingEvents])
  const header = React.useMemo(
    () => (headerSlot ? headerSlot(imgIdx) : null),
    [headerSlot, imgIdx],
  )

  const handleImageChange = (isNext: boolean) => () => {
    if (isNext) {
      setImgIdx(prev => {
        onImageChange?.(prev + 1)
        return prev + 1
      })
    } else {
      setImgIdx(prev => {
        onImageChange?.(prev - 1)
        return prev - 1
      })
    }
  }
  const handleRotate = () => {
    if (trackingEvents?.rotate) trackingManager.track(trackingEvents.rotate)

    setRotateDeg(prev => {
      const rotateArr = [...prev]
      if ((prev[imgIdx] ?? 0) + 1 === 4) {
        rotateArr[imgIdx] = 0
      } else {
        rotateArr[imgIdx] = (prev[imgIdx] ?? 0) + 1
      }
      return rotateArr
    })
  }

  const activeItem = gallery[imgIdx]
  const isGalleryString = typeof activeItem === 'string'
  const supportRotation =
    activeItem && !isGalleryString && ['PDF', 'VIDEO'].includes(activeItem.type)

  return (
    <>
      {header}
      <div
        className={`w-[405px] h-[405px] relative bg-tertiary-grey-300 rounded-sm flex justify-center items-center ${className}`}
      >
        {gallery.length > 0 && (
          <Media
            allowZoom={allowZoom}
            gallery={gallery}
            imgIdx={imgIdx}
            isGalleryString={isGalleryString}
            trackingEvents={trackingEvents}
            rotateDeg={rotateDeg}
          />
        )}
        {!supportRotation && (
          <div className=" border border-tertiary-grey-200 rounded-sm bg-mono-white absolute bottom-2 left-2">
            <FrankieButton
              intent="subtle"
              size="xs"
              className="border border-solid border-tertiary-grey-200 rounded-none"
              onClick={handleRotate}
              singleIcon={{ name: 'mdiRotateRight' }}
            />
          </div>
        )}

        {gallery.length > 1 && (
          <div className="border border-tertiary-grey-200 flex bg-mono-white rounded-sm absolute bottom-2 right-2">
            <FrankieButton
              intent="subtle"
              disabled={imgIdx === 0}
              size="xs"
              className="border-r border-solid border-tertiary-grey-200 rounded-none"
              onClick={handleImageChange(false)}
              singleIcon={{ name: 'mdiChevronLeft' }}
            />
            <FrankieButton
              intent="subtle"
              size="xs"
              disabled={imgIdx === gallery.length - 1}
              onClick={handleImageChange(true)}
              singleIcon={{ name: 'mdiChevronRight' }}
            />
          </div>
        )}
      </div>
    </>
  )
}
