import React, { useState } from 'react'

import { FrankieButton } from 'frankify/src'

import { ImageGallery, GalleryItem } from 'shared/image-gallery'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { IndividualOcrOverlay } from '../individual-ocr-overlay/individual-ocr-overlay'

type Props = {
  gallery: GalleryItem[]
  entityId: string
}

export function IndividualOcrGallery({ gallery, entityId }: Props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [createOverlay] = useOverlay()

  const handleExpand = () => {
    trackingManager.track(
      TrackingEventsTypes.WorkflowEventsBiometricsCompareShow,
    )
    createOverlay(
      <IndividualOcrOverlay
        entityId={entityId}
        defaultIndex={activeIndex}
        gallery={gallery}
      />,
      { className: '!p-6', closeButtonClassName: '!top-6 !right-6' },
    )
  }

  const activeItem = gallery[activeIndex] ?? {}
  const isVideo = activeItem.type === 'VIDEO'

  const handleDownload = async () => {
    trackingManager.track(TrackingEventsTypes.WorkflowEventsBiometricsDownload)
    const blob = await fetch(activeItem.url).then(r => r.blob())
    const imageUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    const isUrlNameCorrect =
      (activeItem.url.split('.').at(-1)?.length ?? 0) <= 3
    link.href = imageUrl
    link.download = isUrlNameCorrect ? activeItem.url : activeItem.id
    link.target = '_blank'
    link.click()
  }

  const isThumbsVisible = gallery.length > 1

  return (
    <div className="row-start-1 row-end-6 ">
      <div className="shrink flex flex-col gap-3">
        <div className="flex gap-2 flex-col bg-tertiary-grey-200 rounded-sm p-2">
          <div className="text-center">
            {!isVideo ? (
              <button
                onClick={handleExpand}
                className="w-[250px] mx-auto cursor-pointer"
                aria-label="Expand image"
                type="button"
              >
                <img src={activeItem.url} alt="selfie" className="w-full" />
              </button>
            ) : (
              <video
                loop
                controls
                onPlay={() =>
                  trackingManager.track(
                    TrackingEventsTypes.WorkflowEventsBiometricsPlay,
                  )
                }
                key={activeItem.id}
                className="w-[250px] mx-auto"
              >
                <track kind="captions" />
                <source src={activeItem.url} type="video/mp4" />
              </video>
            )}
          </div>
          <div className="flex justify-between">
            <FrankieButton
              className="p-1.5"
              noStyles
              onClick={handleExpand}
              singleIcon={{ name: 'mdiArrowExpand' }}
            />
            <FrankieButton
              className="p-1.5"
              noStyles
              onClick={handleDownload}
              singleIcon={{ name: 'mdiDownload' }}
            />
          </div>
        </div>
        {isThumbsVisible && (
          <ImageGallery
            gallery={gallery}
            selected={activeIndex}
            onChange={idx => {
              setActiveIndex(idx)
              trackingManager.track(
                TrackingEventsTypes.WorkflowEventsBiometricsThumbnailClick,
              )
            }}
          />
        )}
      </div>
    </div>
  )
}
