import React, { Fragment, useState } from 'react'

import { Collapse } from '@mui/material'
import cx from 'classnames'

import {
  FrankieBadge,
  FrankieButton,
  FrankieIcon,
  FrankieTooltip,
} from 'frankify/src'

import { documentRowQa } from 'features/individual-idv-check/qa/individual-idv-check.qa'

import { useGlobalAppState } from 'entities/routing'

import { DocumentThumbs } from 'shared/document-thumbs'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import { INDIVIDUAL_IDV_CHECK_KEY } from '../../individual-idv-check.key'
import { individualIdvCheckEn } from '../../locale/individual-idv-check.en'
import { DocumentInfo } from '../../state/individual-idv-check/individual-idv-check.state'
import { IndividualIdvModal } from '../individual-idv-modal/individiual-idv-modal'

export const REACT_SIDEBAR_CUSTOM_EVENT = 'react-portal-sidebar'
export const REACT_SIDEBAR_CUSTOM_EVENT_DETAIL = 'isOpen'

type Props = {
  documentInfo: DocumentInfo
}

export function IDVDocumentRow({ documentInfo }: Props) {
  const { title, values, report, attachments } = documentInfo
  const t = useI18n([INDIVIDUAL_IDV_CHECK_KEY], { keys: individualIdvCheckEn })
  const { data } = useGlobalAppState()

  const [open, setOpen] = useState(false)

  useTrackingShowEvents({
    data: open,
    eventsType: TrackingEventsTypes.WorkflowEventsDocumentFullReportShow,
  })

  const failingSections = report.filter(
    reportSection =>
      reportSection.success === false && reportSection.key !== 'Scores',
  )

  const failedCount = failingSections.length

  const [createOverlay] = useOverlay()

  const handleExpand = (idx?: number) => {
    createOverlay(
      <IndividualIdvModal documentInfo={documentInfo} defaultIdx={idx} />,
      {
        closeButtonClassName: '!top-6 !right-6',
        className: '!p-6',
      },
    )
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="text-md text-tertiary-grey-800 gap-2 font-semibold flex items-center">
        {t(
          `documentType.${
            title as keyof typeof individualIdvCheckEn.documentType
          }`,
        )}
      </div>

      <div className="flex flex-col tablet:flex-row gap-6 ">
        <div className="flex max-w-[724px] gap-6">
          <div className="desktop:w-64 w-36">
            <DocumentThumbs
              gallery={attachments || []}
              handleExpand={handleExpand}
              trackingEvents={[
                TrackingEventsTypes.WorkflowEventsDocumentFront,
                TrackingEventsTypes.WorkflowEventsDocumentBack,
              ]}
            />
          </div>

          <div className="flex border-tertiary-grey-200 border-r-solid border-r flex-col gap-3">
            {values.map(value => (
              <div className="" key={value.label}>
                <div className="text-tertiary-grey-800 w-36 font-medium !inline-block">
                  {value.label}
                </div>
                <span className="text-tertiary-grey-700  ">{value.value}</span>
                {value.success ? (
                  <FrankieIcon
                    name="mdiCheckCircle"
                    size="xs"
                    className="text-tertiary-green-600 !inline-block align-text-top ml-1"
                  />
                ) : (
                  <FrankieIcon
                    name="mdiCloseCircle"
                    size="xs"
                    className="text-tertiary-red-700 !inline-block align-text-top ml-1"
                  />
                )}
              </div>
            ))}
            <FrankieButton
              noStyles
              className="text-left font-bold self-start text-primary-800"
              onClick={() => handleExpand(0)}
            >
              {t('viewAllOCR')}
            </FrankieButton>
          </div>
        </div>

        <div
          className={cx(
            data?.isSidebarOpen
              ? 'basis-[25%] min-w-[25%] desktop:basis-[40%] desktop:min-w-[40%]'
              : 'basis-[40%] min-w-[40%]',
          )}
        >
          <div className="flex flex-wrap gap-y-2">
            {documentInfo.score.idvScore && (
              <div className="w-40">
                <p className="font-semibold text-tertiary-grey-800">
                  {t('idScore')}
                </p>
                <p
                  className={cx(
                    'font-bold text-2xl',
                    documentInfo.score.idvScore.success
                      ? 'text-tertiary-green-600'
                      : 'text-tertiary-red-700',
                  )}
                >
                  {t('score', { score: documentInfo.score.idvScore.score })}
                </p>
              </div>
            )}
            {documentInfo.score.ocrScore && (
              <div className="w-40">
                <p className="font-semibold text-tertiary-grey-800">
                  {t('ocrScore')}
                </p>
                <p
                  className={cx(
                    'font-bold text-2xl',
                    documentInfo.score.ocrScore.success
                      ? 'text-tertiary-green-600'
                      : 'text-tertiary-red-700',
                  )}
                >
                  {t('score', { score: documentInfo.score.ocrScore.score })}
                </p>
              </div>
            )}
          </div>

          <div className="font-semibold text-tertiary-grey-800 mt-3 mb-2">
            {t('documentIssues')}
          </div>

          <div className="flex flex-wrap gap-2">
            {!failedCount && t('noIssues')}

            {failingSections.map(section => (
              <FrankieTooltip
                key={section.title}
                position="top"
                title={section.title}
              >
                <FrankieBadge
                  theme="red"
                  text={section.title}
                  testId={{
                    badge: documentRowQa.issueBadge(section.key),
                  }}
                />
              </FrankieTooltip>
            ))}
          </div>
        </div>
      </div>

      <div className="flex gap-2 py-2">
        {!!failedCount && (
          <div className="bg-tertiary-red-700 text-mono-white  text-center rounded-full aspect-square h-5 w-5">
            {failedCount}
          </div>
        )}

        <div className="font-semibold text-tertiary-grey-800">
          {t('showFullReport')}
        </div>

        <FrankieButton
          onClick={() => setOpen(o => !o)}
          noStyles
          singleIcon={{
            name: open ? 'mdiChevronUp' : 'mdiChevronDown',
            size: 'xs',
          }}
        />
      </div>

      <Collapse className={open ? 'mb-2' : '-mb-4'} in={open}>
        <div className="flex flex-wrap">
          {report.map(reportSection => {
            const isScoresCategory = reportSection.key === 'Scores'

            return (
              <div
                key={reportSection.key}
                className="basis-1/5 pr-4 min-w-[199px]"
              >
                <div className="text-tertiary-grey-800 font-medium flex gap-1.5 mb-2">
                  {!reportSection.isStatusUnknown && (
                    <FrankieIcon
                      name={
                        reportSection.success
                          ? 'mdiCheckCircle'
                          : 'mdiCloseCircle'
                      }
                      size="xs"
                      className={cx(
                        'mt-[2.5px]',
                        reportSection.success
                          ? 'text-tertiary-green-600'
                          : 'text-tertiary-red-700',
                      )}
                    />
                  )}
                  {reportSection.title}
                </div>
                {reportSection.children?.map(report => (
                  <Fragment key={report.key}>
                    <div
                      className={cx(
                        'text-tertiary-grey-500 font-medium text-xs flex gap-1.5 mb-1.5',
                        !isScoresCategory && 'ml-4 ',
                      )}
                    >
                      {!report.value && (
                        <FrankieIcon
                          name={
                            report.success ? 'mdiCheckCircle' : 'mdiCloseCircle'
                          }
                          size="2xs"
                          className={cx(
                            'mt-[3px]',
                            report.success
                              ? 'text-tertiary-green-600'
                              : 'text-tertiary-red-700',
                          )}
                        />
                      )}
                      <div>{report.title}</div>
                    </div>
                    {report.value && (
                      <div
                        className={cx(
                          'flex gap-2 justify-start items-center mb-1.5',
                          !isScoresCategory && 'ml-4',
                        )}
                      >
                        <FrankieIcon
                          name={
                            report.success ? 'mdiCheckCircle' : 'mdiCloseCircle'
                          }
                          size="2xs"
                          className={cx(
                            'mt-[3px]',
                            report.success
                              ? 'text-tertiary-green-600'
                              : 'text-tertiary-red-700',
                          )}
                        />
                        <span>{report.value}</span>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            )
          })}
        </div>
      </Collapse>
    </div>
  )
}
