import React, { useMemo } from 'react'

import { DocumentInformation } from 'entities/entity'

import { DocumentThumbs } from 'shared/document-thumbs'
import { GalleryImage } from 'shared/document-thumbs/ui/document-thumbs'
import { convertDataURItoBlob, makeDataURL } from 'shared/file'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { DocumentOverlay } from './document-overlay'
import {
  documentSideOptionMap,
  DocumentSideOptionTypes,
  photoPageIdTypes,
} from '../../model/document-id-types.model'

type Props = {
  document: DocumentInformation
  className?: string
  extraData: { label: string; value?: string }[]
  trackingEvents?: {
    gallery: TrackingEventsTypes[]
    rotate: TrackingEventsTypes
    zoom: TrackingEventsTypes
    show: TrackingEventsTypes
  }
}

export function DocumentViewF2R2({
  document,
  extraData,
  trackingEvents,
  className = '',
}: Props) {
  const [createOverlay] = useOverlay()

  const attachments = useMemo(() => {
    type Attachment = {
      types: DocumentSideOptionTypes[]
      urls: GalleryImage[]
      isImages: boolean[]
    }
    const empty: Attachment = { urls: [], isImages: [], types: [] }
    return (
      document.attachments?.reduce((acc, attachment, index) => {
        const multipleAttachments = (document.attachments?.length ?? 0) > 1
        const { mimeType } = attachment
        const fileUrl =
          'base64' in attachment.data ? attachment.data.base64 : ''

        if (!fileUrl || !mimeType) {
          // Safety check
          return acc
        }
        const temp_url = makeDataURL(mimeType, fileUrl)
        const blob = convertDataURItoBlob(temp_url)
        const url = window.URL.createObjectURL(blob)

        const type: DocumentSideOptionTypes =
          (!multipleAttachments && photoPageIdTypes.includes(document.type)) ||
          !attachment.side
            ? DocumentSideOptionTypes.photoPage
            : documentSideOptionMap[attachment.side]

        const isImage = mimeType.split('/').includes('image')
        acc.urls.push({
          url,
          mimeType,
          id: attachment.attachmentId,
          side: attachment.side,
          type: attachment.type,
          createdAt: attachment.createdAt,
        } as GalleryImage)
        acc.isImages.push(isImage)
        acc.types.push(type)

        return acc
      }, empty) || empty
    )
  }, [document.type, document.attachments])

  const { urls } = attachments
  if (!urls.length) return null

  const handleClick = (idx?: number) => {
    if (trackingEvents?.show) trackingManager.track(trackingEvents.show)
    createOverlay(
      <DocumentOverlay
        urls={urls}
        document={document}
        extraData={extraData}
        trackingEvents={trackingEvents}
        index={idx}
      />,
      {
        className: '!p-6',
        closeButtonClassName: '!top-6 !right-6',
      },
    )
  }

  return (
    <DocumentThumbs
      className={className}
      gallery={urls}
      trackingEvents={trackingEvents?.gallery}
      handleExpand={handleClick}
    />
  )
}
