import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  FrankieButton,
  FrankieDivider,
  FrankieIcon,
  FrankieTooltip,
} from 'frankify/src'

import { useSupportingDocumentDelete } from 'features/applicant-supporting-documents'
import { useOrganisationSettingsQuery } from 'features/organisation-settings'

import {
  ApplicantResponse,
  hasSardineCheckResult,
  useApplicantDataQuery,
} from 'entities/applicant'
import { IRecipeOption } from 'entities/recipe'
import { PermissionTypes } from 'entities/role'
import {
  useHasFeatureFlag,
  useHasPermission,
  useSessionQuery,
} from 'entities/session'

import { SelectFormField } from 'shared/form'
import { ErrorResponse } from 'shared/http'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'

import { INDIVIDUAL_PROFILE_KEY } from '../../individual-profile.keys'
import { individualProfileEn } from '../../locale/new-profile.en'
import { transformDataForUpdate } from '../../model/edit-form-data.model'
import { mapDataToForm } from '../../model/edit-individual.model'
import {
  transformData,
  validateMKYCDocuments,
} from '../../model/form-data.model'
import {
  IIndividualProfileInputs,
  IndividualProfileInputTypes,
  individualProfileDefaultValues,
  manualIdvPermissionObject,
} from '../../model/form.model'
import { useNewProfileCheck } from '../../mutation/check-new-profile.mutation'
import { useSaveIndividualProfile } from '../../mutation/create-profile-individual.mutation'
import { useUpdateIndividualProfile } from '../../mutation/update-profile-individual.mutation'
import { individualProfileVueMigratedQa } from '../../qa/individual-profile.qa'
import { AddMoreButton } from '../add-more-button/add-more-button'
import { AddressForm } from '../address-form/address-form'
import { ConsentForm } from '../consent-form/consent-form'
import { CreditHeader } from '../credit-header/credit-header'
import { DocumentForm } from '../document-from/document-form'
import { ForceCheckForm } from '../force-check-form/force-check-form'
import { PersonalInfo } from '../personal-info-form/personal-info-form'

const getProfileOptions = (profileOptions: IRecipeOption[] | undefined) => {
  if (!profileOptions) return [{ label: 'Automatic Selection', value: 'auto' }]

  switch (profileOptions.length) {
    case 0:
      return [{ label: 'Automatic Selection', value: 'auto' }]
    default:
      return profileOptions
  }
}

export type Props = {
  applicantId?: string
  getApplicantGeneralInfoPath: (applicantId: string) => string
}

// eslint-disable-next-line complexity
export function IndividualProfile({
  getApplicantGeneralInfoPath,
  applicantId,
}: Props) {
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  const {
    personalInfoPermission,
    addDocumentPermission,
    deleteDocumentPermission,
    editDocumentPermission,
    switchKycMethodPermission,
  } = useHasPermission(manualIdvPermissionObject)

  const { data: session } = useSessionQuery()
  const { data: applicantData, remove } = useApplicantDataQuery({ applicantId })

  const isSardineCheckResult = hasSardineCheckResult(
    applicantData?.checkSummary.checkResults || [],
  )

  const { canFetchOrganisationSettings } = useHasPermission({
    canFetchOrganisationSettings: PermissionTypes.OrganisationSettingsFetch,
  })

  const [defaultCountry, setDefaultCountry] = useState('AUS')

  const { data: organizationSetting } = useOrganisationSettingsQuery({
    canFetchOrganisationSettings,
  })
  const navigate = useNavigate()

  const profileOptions = getProfileOptions(
    session?.profiles?.profile_options,
  ).filter(option => option.label.toLowerCase() !== 'default')

  const [saveOnly, setSaveOnly] = useState(false)

  const { optionalFieldAddress, hasFraudAlerts } = useHasFeatureFlag({
    optionalFieldAddress: ['optionalFields', 'address'],
    hasFraudAlerts: 'fraudAlerts',
  })

  const form = useForm<IIndividualProfileInputs>({
    mode: 'onTouched',
    reValidateMode: 'onChange',

    defaultValues: individualProfileDefaultValues,
  })

  const [createOverlay] = useOverlay()

  const {
    control,
    watch,
    getValues,
    reset,
    setValue,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
  } = form

  const profileRecipeWatch = watch('recipe')

  const isAddressOptional =
    profileRecipeWatch === 'beneficiary' || optionalFieldAddress

  const handleAddressOptions = (selectedOption: string) => {
    setValue(
      `${selectedOption}.${IndividualProfileInputTypes.isActive}` as keyof IIndividualProfileInputs,
      true,
    )
    setValue(
      `${selectedOption}.${IndividualProfileInputTypes.Country}` as keyof IIndividualProfileInputs,
      defaultCountry,
    )
  }
  const removeAddressSection = (selectedOption: string) => {
    setValue(
      `${selectedOption}.${IndividualProfileInputTypes.isActive}` as keyof IIndividualProfileInputs,
      false,
    )
    setValue(
      `${selectedOption}.${IndividualProfileInputTypes.Country}` as keyof IIndividualProfileInputs,
      defaultCountry,
    )
    setValue(
      `${selectedOption}.${IndividualProfileInputTypes.AddressId}` as keyof IIndividualProfileInputs,
      '',
    )
  }

  const handleSuccess = ({ entityId, ...rest }: { entityId: string }) => {
    const firstName = getValues('personalInfo.firstName')
    const middleName = getValues('personalInfo.middleName')
    const lastName = getValues('personalInfo.lastName')
    const fullName = [firstName, middleName, lastName].join(' ')

    if (applicantId) {
      notification.success(t('hasBeenUpdated', { fullName }))
    } else {
      notification.success(
        t('hasBeenCreated', {
          fullName,
        }),
      )
    }

    const path = getApplicantGeneralInfoPath(entityId)
    remove()
    navigate(path)
  }

  const handleError = (err: ErrorResponse) => {
    const isMissingDocs = err.response?.data.issues.find(i =>
      i.issues.includes('IdentityDocs'),
    )
    if (isMissingDocs) {
      notification.error(t('missingDocsError'))
    } else {
      notification.error(t('profileCheckError'))
    }
  }
  const handleCreditHeaderIssue = (
    message: string,
    handleSuccess: () => void,
    entityId: string,
  ) => {
    createOverlay(
      <CreditHeader
        handleSuccess={handleSuccess}
        entityId={entityId}
        message={message}
      />,
      { className: '!p-0', closeButtonClassName: 'hidden' },
    )
  }

  const { mutate: profileCheck, isLoading: isLoadingChecks } =
    useNewProfileCheck(handleSuccess, handleCreditHeaderIssue, handleError)

  const {
    mutate,
    data: individualResponseData,
    isSuccess,
    isLoading,
  } = useSaveIndividualProfile()

  const {
    mutate: mutateUpdate,
    data: individualResponseDataUpdated,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateIndividualProfile({ applicantId: applicantId! })

  const { mutateAsync: removeDocumentMutate, isLoading: isLoadingDocDelete } =
    useSupportingDocumentDelete({
      applicantId: applicantId || '',
      disableProfileDocCheckUpdate: true,
      disableRefetch: true,
    })

  useEffect(() => {
    if (applicantData) {
      const newFormValues = mapDataToForm(applicantData)
      reset(newFormValues)
    }
  }, [applicantData, reset])

  useEffect(() => {
    if (organizationSetting) {
      const countrySetting = organizationSetting.find(
        item => item.name === 'country',
      )
      if (
        countrySetting?.value &&
        countrySetting.value !==
          individualProfileDefaultValues[
            IndividualProfileInputTypes.CurrentResidentialAddress
          ][IndividualProfileInputTypes.Country]
      ) {
        if (!applicantId) {
          setValue(
            'currentResidentialAddress.country' as keyof IIndividualProfileInputs,
            countrySetting.value,
          )
          setValue(
            'previousResidentialAddress.country' as keyof IIndividualProfileInputs,
            countrySetting.value,
          )
          setValue(
            'mailingAddress.country' as keyof IIndividualProfileInputs,
            countrySetting.value,
          )
          setValue(
            'document.0.country' as keyof IIndividualProfileInputs,
            countrySetting.value,
          )
        }
        setDefaultCountry(countrySetting.value)
      }
    }

    return () => {}
  }, [organizationSetting, setValue, applicantId])

  useEffect(() => {
    const entityId =
      individualResponseDataUpdated?.data.entityId ||
      individualResponseData?.data.entityId
    const forceCheck = getValues('forceCheck')
    if ((isSuccess || isUpdateSuccess) && entityId) {
      if (saveOnly) {
        handleSuccess({ entityId })
      } else {
        profileCheck({
          entityId,
          options: { forceCheck, uploadDocs: false, noInvalidate: false },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSuccess,
    isUpdateSuccess,
    individualResponseDataUpdated,
    profileCheck,
    individualResponseData,
    getValues,
  ])

  const isPreviousAddressActive = watch(
    `${IndividualProfileInputTypes.PreviousResidentialAddress}.${IndividualProfileInputTypes.isActive}` as keyof IIndividualProfileInputs,
  )
  const isMailingAddressActive = watch(
    `${IndividualProfileInputTypes.MailingAddress}.${IndividualProfileInputTypes.isActive}` as keyof IIndividualProfileInputs,
  )

  const updateForm = (
    formData: IIndividualProfileInputs,
    updatedApplicantData: ApplicantResponse | undefined = applicantData,
  ) => {
    if (updatedApplicantData && applicantId) {
      const payload = transformDataForUpdate(
        formData,
        mapDataToForm(updatedApplicantData),
        updatedApplicantData,
      )

      const isValidMKYCDocuments = validateMKYCDocuments(
        payload.document,
        session?.mkyc_rules,
        formData.kycMethod === 'manual',
      )

      if (isValidMKYCDocuments) mutateUpdate({ profile: payload, saveOnly })
      else toast.error(t('manualKycError'))
    } else {
      const payload = transformData(formData)

      const isValidMKYCDocuments = validateMKYCDocuments(
        payload.document,
        session?.mkyc_rules,
        formData.kycMethod === 'manual',
      )

      if (isValidMKYCDocuments) mutate({ profile: payload, saveOnly })
      else toast.error(t('manualKycError'))
    }
  }

  /**
   * @description call delete document API first if email OR phone number is removed
   * and then call the updateForm for the update API
   * @param formData IIndividualProfileInputs
   * @returns updateForm
   */
  const handleForm = async (formData: IIndividualProfileInputs) => {
    const { email, phoneNumber } = applicantData?.applicantDetails || {}
    const { email: emailForm, phoneNumber: phoneNumberForm } =
      formData[IndividualProfileInputTypes.PersonalInfo]

    const emailIsRemoved = !emailForm && email?.documentId
    const phoneNumberIsRemoved = !phoneNumberForm && phoneNumber?.documentId

    // if none of the email or phone number is removed then just update the form
    if (!emailIsRemoved && !phoneNumberIsRemoved) return updateForm(formData)

    if (applicantData) {
      let filteredApplicantData: ApplicantResponse = { ...applicantData }

      const filterApplicantData = (key: 'email' | 'phoneNumber') => ({
        ...filteredApplicantData,
        applicantDetails: {
          ...filteredApplicantData.applicantDetails,
          [key]: {
            documentId: '',
            idNumber: '',
          },
        },
      })

      if (emailIsRemoved && email.documentId) {
        await removeDocumentMutate(email.documentId)
        filteredApplicantData = filterApplicantData('email')
      }

      if (phoneNumberIsRemoved && phoneNumber.documentId) {
        await removeDocumentMutate(phoneNumber.documentId)
        filteredApplicantData = filterApplicantData('phoneNumber')
      }

      return updateForm(formData, filteredApplicantData)
    }

    return updateForm(formData)
  }

  const isButtonDisabled =
    !!Object.keys(errors).length ||
    isLoading ||
    isUpdateLoading ||
    isLoadingChecks ||
    isLoadingDocDelete

  return (
    <form onSubmit={handleSubmit(handleForm)}>
      <div className="mx-auto max-w-[895px] px-5">
        <span className="text-tertiary-grey-500">
          {applicantId && `${t('frankieId')} - ${applicantId}`}
        </span>
        <div
          className="flex flex-wrap relative"
          data-qa={individualProfileVueMigratedQa.recipeDropdown}
        >
          <div className="absolute left-[48px] top-[17px]">
            <FrankieTooltip
              position="right"
              title={t('recipeToolTip')}
              className="max-w-[300px] text-right"
            >
              <div>
                <FrankieIcon
                  name="mdiInformation"
                  size="sm"
                  className="text-tertiary-grey-300"
                />
              </div>
            </FrankieTooltip>
          </div>
          <SelectFormField
            className="mt-4  mb-2 basis-1/4"
            label={t('recipe')}
            control={control}
            disabled={!!applicantId && !personalInfoPermission}
            name={IndividualProfileInputTypes.Recipe}
            options={profileOptions}
          />
        </div>
        <FrankieDivider className="my-5" />
        <fieldset disabled={!!applicantId && !personalInfoPermission}>
          <PersonalInfo
            form={form}
            hidePhoneAndEmail={hasFraudAlerts && isSardineCheckResult}
          />

          <FrankieDivider className="my-5" />
          <div className="">
            <AddressForm
              form={form}
              isOptionalField={isAddressOptional}
              editMode={!!applicantData}
              name={IndividualProfileInputTypes.CurrentResidentialAddress}
              heading={t('currentAddress')}
            />

            {isPreviousAddressActive && (
              <AddressForm
                editMode={!!applicantData}
                isOptionalField={isAddressOptional}
                form={form}
                name={IndividualProfileInputTypes.PreviousResidentialAddress}
                heading={t('previousResidentialAddress')}
                remove={() =>
                  removeAddressSection(
                    IndividualProfileInputTypes.PreviousResidentialAddress,
                  )
                }
              />
            )}

            {isMailingAddressActive && (
              <AddressForm
                isOptionalField={isAddressOptional}
                editMode={!!applicantData}
                showSameAs
                form={form}
                name={IndividualProfileInputTypes.MailingAddress}
                heading={t('mailingAddress')}
                remove={() =>
                  removeAddressSection(
                    IndividualProfileInputTypes.MailingAddress,
                  )
                }
              />
            )}

            {(!isMailingAddressActive || !isPreviousAddressActive) && (
              <div className="my-4 mt-8">
                <AddMoreButton
                  label={t('addressFrom.addAnotherAddress')}
                  options={[
                    {
                      label: t('previousResidentialAddress'),
                      value:
                        IndividualProfileInputTypes.PreviousResidentialAddress,
                      iconProps: {
                        name: 'mdiHomeOutline',
                        className: 'text-tertiary-grey-200',
                        size: 'sm',
                      },
                      isActive: !isPreviousAddressActive,
                    },
                    {
                      label: t('mailingAddress'),
                      value: IndividualProfileInputTypes.MailingAddress,
                      iconProps: {
                        name: 'mdiEmailOutline',
                        className: 'text-tertiary-grey-200',
                        size: 'sm',
                      },
                      isActive: !isMailingAddressActive,
                    },
                  ]}
                  selectedOption={option => {
                    handleAddressOptions(option)
                  }}
                />
              </div>
            )}
          </div>
        </fieldset>
        <FrankieDivider className="mt-10 mb-5" />
        <DocumentForm
          defaultCountry={defaultCountry}
          switchKycDisabled={!!applicantId && !switchKycMethodPermission}
          removeDisabled={!!applicantId && !deleteDocumentPermission}
          addDisabled={!!applicantId && !addDocumentPermission}
          editDisabled={!!applicantId && !editDocumentPermission}
          applicantId={applicantId}
          form={form}
        />
        <FrankieDivider className="my-5" />

        <ConsentForm form={form} />

        {!!applicantData && <ForceCheckForm form={form} />}

        <div className="flex mt-6">
          <div
            className={!isValid ? 'cursor-not-allowed' : ''}
            onMouseEnter={() => trigger(undefined, { shouldFocus: true })}
          >
            <FrankieButton
              className={`px-5 py-0 mr-6 ${
                !isValid ? 'cursor-not-allowed' : ''
              }`}
              size="sm"
              onClick={() => setSaveOnly(false)}
              type="submit"
              disabled={isButtonDisabled}
              testId={{
                button: individualProfileVueMigratedQa.document.saveAndVerify,
              }}
            >
              {watch(IndividualProfileInputTypes.KycMethod) ===
              IndividualProfileInputTypes.Manual
                ? t('saveApprove')
                : t('saveVerify')}
            </FrankieButton>
          </div>
          <div
            className={!isValid ? 'cursor-not-allowed' : ''}
            onMouseEnter={() => trigger(undefined, { shouldFocus: true })}
          >
            <FrankieButton
              className={`px-5 py-0 mr-6 ${
                !isValid ? 'cursor-not-allowed' : ''
              }`}
              onClick={() => setSaveOnly(true)}
              size="sm"
              intent="subtle"
              type="submit"
              disabled={isButtonDisabled}
              testId={{
                button: individualProfileVueMigratedQa.document.saveChanges,
              }}
            >
              {t('saveChanges')}
            </FrankieButton>
          </div>
        </div>
      </div>
    </form>
  )
}
