import React, { useEffect, useMemo } from 'react'

import classNames from 'classnames'

import { FrankieDivider } from 'frankify/src'

import { ApplicantId } from 'entities/applicant'
import {
  useEntityDataQuery,
  useGetWorkflowEventsData,
  WorkflowExecutionResultEnum,
  WorkflowStepResultEnum,
} from 'entities/entity'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { failVariants } from '../../model/applicant-workflow-events.model'
import { entityVerificationEventsQa } from '../../qa/applicant-workflow-events.qa'
import {
  useGetWorkFlowDataWithSources,
  useOrderWithFailedAndPassed,
} from '../../state/get-workflow-events.query'
import { VerificationDocumentCard } from '../entity-verification-document-card/entity-verification-document-card'
import { VerificationHeader } from '../entity-verification-header/entity-verification-header'
import { PersonalInfoSection } from '../entity-verification-personal-info-section/entity-verification-personal-info-section'

type PropsType = {
  entityId: ApplicantId
}

function borderStyleConfig({
  eventResult,
}: {
  eventResult: WorkflowStepResultEnum
}) {
  if (failVariants.includes(eventResult)) return 'border-tertiary-red-500'

  switch (eventResult) {
    case WorkflowStepResultEnum.UNCHECKED:
      return 'border-tertiary-grey-500'
    default:
      return 'border-tertiary-grey-200'
  }
}

export function EntityVerificationEvents({ entityId }: PropsType) {
  const { data, isLoading } = useEntityDataQuery(entityId, 'base64')

  const { data: workFlowData } = useGetWorkflowEventsData({
    entityId,
  })

  const { docsToBeDisplayed, isManuallyVerified, isNotUnchecked, isArchived } =
    useGetWorkFlowDataWithSources({
      entityId,
      docR1: true,
    })

  const { eventStepper } = useOrderWithFailedAndPassed({
    entityId,
  })

  const eventResult = useMemo(
    () =>
      workFlowData?.workflowSummaries
        ?.at(0)
        ?.workflowResultData?.workflowStepResults?.find(
          event => event.stepName === 'KYC',
        )?.result || WorkflowStepResultEnum.UNCHECKED,
    [workFlowData],
  )

  useEffect(() => {
    if (workFlowData) {
      const summary = workFlowData.workflowSummaries?.at(0)
      trackingManager.trackWithProps(
        TrackingEventsTypes.WorkflowEventEntityVerificationShowIndividual,
        {
          entityId,
          status: summary?.status,
          issues: summary?.issues?.map(issue => issue.issue),
          workflowExecutionId: summary?.workflowExecutionId,
          workflowName: summary?.workflowName,
        },
      )
    }
  }, [entityId, workFlowData])

  const handleClick = (event?: TrackingEventsTypes) => () => {
    if (event)
      trackingManager.trackWithProps(event, {
        entityId,
      })
  }

  const kycVisible = useMemo(
    () =>
      (workFlowData?.workflowSummaries?.at(0)?.status ??
        WorkflowExecutionResultEnum.UNCHECKED) ===
        WorkflowExecutionResultEnum.UNCHECKED ||
      eventStepper.some(step => step.eventName === 'KYC'),
    [workFlowData?.workflowSummaries, eventStepper],
  )

  if (isLoading) return null
  return (
    <div className="grow-0">
      {data && isNotUnchecked && eventStepper.length > 0 && (
        <div
          className={classNames(
            kycVisible ? 'mb-10' : 'mb-4',
            'flex gap-8 relative',
          )}
          data-qa={entityVerificationEventsQa.container}
        >
          {eventStepper.map((stepper, idx, self) => (
            <div
              className="relative pr-8"
              data-qa={entityVerificationEventsQa.eventStepperWrapper}
              key={stepper.text}
            >
              <a
                data-qa={entityVerificationEventsQa.navigateToEventName}
                href={`#${stepper.eventName}`}
                onClick={handleClick(stepper.track)}
              >
                <div
                  className="max-w-fit flex flex-col items-center cursor-pointer gap-1"
                  key={stepper.text}
                >
                  {stepper.config}
                  <div className="font-medium text-tertiary-grey-800 leading-5 w-[120px] px-2 text-center">
                    {stepper.text}
                  </div>
                </div>
              </a>
              {idx < self.length - 1 && (
                <FrankieDivider className="absolute w-full top-3 left-1/2" />
              )}
              <div />
            </div>
          ))}
        </div>
      )}
      {data && kycVisible && (
        <div
          data-qa={entityVerificationEventsQa.verificationHeaderSectionWrapper}
          className={`border rounded-1.5 flex flex-col pt-5 px-6 pb-6 ${borderStyleConfig(
            { eventResult },
          )}`}
        >
          <VerificationHeader
            isArchived={isArchived}
            isManuallyVerified={isManuallyVerified}
            eventResult={eventResult}
          />
          <PersonalInfoSection entityId={entityId} eventResult={eventResult} />
          {docsToBeDisplayed?.map(doc => (
            <>
              <FrankieDivider className="my-8" />
              <VerificationDocumentCard data={doc} />
            </>
          ))}
        </div>
      )}
    </div>
  )
}
