import { useCallback } from 'react'

import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'

import type { IFileValidationConfig } from './document.model'
import { DOCUMENT_KEY, documentEn } from '../locale/document.en'

export const useFileValidation = ({
  maxFileSize,
  supportedFileTypes,
  maxFilenameLength,
}: IFileValidationConfig) => {
  const t = useI18n([DOCUMENT_KEY], { keys: documentEn })
  const validateFile = useCallback(
    (file: File): boolean => {
      // File size validation (convert MB to bytes)
      if (file.size > +(maxFileSize * 1000000).toFixed(0)) {
        notification.error(
          t('message.invalidFileSize', { fileSize: maxFileSize }),
        )
        return false
      }

      // File type validation
      if (!supportedFileTypes.some(type => RegExp(type).test(file.type))) {
        notification.error(
          t('message.invalidFileType', {
            fileTypes: supportedFileTypes
              .map(item => item.split('/')[1])
              .join(', '),
          }),
        )
        return false
      }

      // Filename validation
      if (
        file.name.length > maxFilenameLength ||
        /[<>\\/'"`]/.test(file.name)
      ) {
        notification.error(
          t('message.invalidFileName', {
            maxLength: maxFilenameLength,
          }),
        )
        return false
      }

      return true
    },
    [maxFileSize, supportedFileTypes, maxFilenameLength, t],
  )

  return validateFile
}
