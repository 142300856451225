import { useMemo } from 'react'

import { ENTITY_KEY } from 'entities/entity/entity.key'
import { entityEn } from 'entities/entity/locale/entity.en'
import {
  getDocumentWithLabelData,
  getProfileInfoWithLabelData,
} from 'entities/entity/model/entity-label-data-model'
import {
  getDocumentWithLabelDataF2R2,
  getProfileInfoWithLabelDataF2R2,
} from 'entities/entity/model/entity-label-data.model.f2-r2'
import { Args, DocumentType } from 'entities/entity/model/entity.model'

import { useI18n } from 'shared/i18n'

import {
  useEntityDataQuery,
  useFrankie2R2Customer,
} from '../entity-data/entity-data.query'

const SUPPORTED_DOCUMENTS = [
  DocumentType.DRIVERS_LICENSE,
  DocumentType.PASSPORT,
  DocumentType.NATIONAL_HEALTH_ID,
  DocumentType.SELF_IMAGE,
]

export const useEntityLabelDataState = ({ entityId }: Args) => {
  const { data: entity } = useEntityDataQuery(entityId, 'base64')
  const t = useI18n(ENTITY_KEY, { keys: entityEn })
  const frankieF2R2 = useFrankie2R2Customer()

  const documentLabelDataF2R2 = getDocumentWithLabelDataF2R2({
    documents: entity?.individual?.documents?.IDENTITY,
    individual: entity?.individual,
    singleAddress: true,
    t,
  })

  const documentLabelData = getDocumentWithLabelData({
    documents: entity?.individual?.documents?.IDENTITY,
    individual: entity?.individual,
    singleAddress: true,
    t,
  })

  const documents = useMemo(
    () =>
      (frankieF2R2 ? documentLabelDataF2R2 : documentLabelData)?.filter(doc =>
        SUPPORTED_DOCUMENTS.includes(doc.type),
      ),
    [documentLabelData, documentLabelDataF2R2, frankieF2R2],
  )

  const personalInfoLabelDataF2R2 = getProfileInfoWithLabelDataF2R2({
    individual: entity?.individual,
    singleAddress: false,
    t,
  })

  const personalInfoLabelData = getProfileInfoWithLabelData({
    individual: entity?.individual,
    singleAddress: false,
    t,
  })

  return {
    documentWithLabelData: documents,
    personalInfoWithLabelData: frankieF2R2
      ? personalInfoLabelDataF2R2
      : personalInfoLabelData,
    documentWithLabelDataR1: documentLabelData,
    personalInfoWithLabelDataR1: personalInfoLabelData,
  }
}
