import { SupplementaryDataAML, useEntityAmlData } from 'entities/entity'

type Args = {
  entityId: string
  processResultId?: string
  historyPage?: boolean
}

const getMatchStrength = (source?: string, currentStrength?: string): string =>
  source === 'ComplyAdvantage' ? '' : currentStrength ?? ''

export const useAmlData = ({
  entityId,
  processResultId,
  historyPage,
}: Args) => {
  const { data } = useEntityAmlData({ entityId, isNonValid: historyPage })
  const selectedAmlData = data.processResults.find(
    processResult => processResult.processResultId === processResultId,
  )

  const supplementaryData = selectedAmlData?.supplementaryData as
    | SupplementaryDataAML
    | undefined

  return {
    amlData: {
      ...selectedAmlData,
      supplementaryData: supplementaryData && {
        ...supplementaryData,
        matchData: {
          ...supplementaryData.matchData,
          strength: getMatchStrength(
            selectedAmlData?.providerResult?.sourceNormalized,
            String(supplementaryData.matchData?.strength),
          ),
        },
      },
    },
  }
}
