import { sentenceCase } from 'change-case'
import { unflatten } from 'flat'

import {
  ProcessResultObject,
  useEntityDataQuery,
  useGetWorkflowEventsData,
} from 'entities/entity'
import {
  DocumentAttachment,
  IndividualsEntityResponse,
  PROCESS_RESULT_STATUS_MAP,
  SupplementaryDataBase,
  WorkflowStepResultEnum,
  ProcessResultManualStatusEnumKYC,
} from 'entities/entity/model/entity.model'

import { getDataFileUrl } from 'shared/file'
import type { GalleryItem } from 'shared/image-gallery'
import { Nullable } from 'shared/typescript'

import { passVariants } from '../../model/individual-idv-check.model'

type Args = {
  entityId: string
  idx: number
}

function getSelfies(
  processResult?: ProcessResultObject<'IDV_FACIAL_COMPARISON'>,
  entity?: IndividualsEntityResponse,
): GalleryItem[] {
  const selfieId = processResult?.supplementaryData?.comparedSelfieId

  const document = entity?.individual?.documents?.IDENTITY?.find(
    doc => doc.documentId === selfieId,
  )
  if (!document) return []

  const images =
    document.attachments?.reduce<GalleryItem[]>((acc, attachment) => {
      if (attachment.data.base64 && attachment.mimeType) {
        acc.push({
          id: attachment.attachmentId,
          url: getDataFileUrl(attachment.mimeType, attachment.data.base64),
          type: attachment.type,
          side: attachment.side,
          createdAt: attachment.createdAt,
        } as GalleryItem)
      }
      return acc
    }, [] as GalleryItem[]) || []

  return images
}

type Report = {
  originalData: string
  originalName: string
  resultNormalized: string
}
export type Node = {
  id: string
  name: string
  data: Report
  success: boolean
  children?: Record<string, Node>
}
function buildTree(flatData: Record<string, Report>): Record<string, Node> {
  const result: Record<string, boolean | string | Report> = {}
  for (const key in flatData) {
    if (Object.prototype.hasOwnProperty.call(flatData, key)) {
      const parts = key.split('.')
      const [, ...paths] = parts
      const newKey = paths.join('.children.')

      result[`${newKey}.id`] = key
      result[`${newKey}.data`] = { ...flatData[key] }
      result[`${newKey}.name`] = sentenceCase(paths[paths.length - 1] || '')
      result[`${newKey}.success`] =
        flatData[key].resultNormalized.toLowerCase() === 'clear'
    }
  }
  return unflatten(result)
}

function mapResultData(
  resultMap: Record<string, Report> = {},
): Record<string, Node> {
  return buildTree(resultMap)
}

export type OcrCheckReport = {
  title: string
  success: Nullable<boolean>
  scoreTKey?: string
  score: number
  children: { title: string; success: Nullable<boolean> }[]
}
function getCheckResults(
  comparison?: ProcessResultObject<'IDV_FACIAL_COMPARISON'>,
  liveness?: ProcessResultObject<'IDV_FACIAL_LIVENESS'>,
): Record<string, Node>[] {
  if (!comparison || !liveness) return []

  const comparisonValues = mapResultData(
    comparison.supplementaryData?.resultMap,
  )
  const livenessValues = mapResultData(liveness.supplementaryData?.resultMap)

  return [comparisonValues, livenessValues]
}

export const useIndividualOcrCheckState = ({ entityId, idx }: Args) => {
  const { data } = useGetWorkflowEventsData({ entityId })
  const { data: entity } = useEntityDataQuery(entityId, 'base64')

  const workflow = data?.workflowSummaries?.at(0)

  const hadOcrChecks = workflow?.steps?.order?.includes('IDV')

  const step = workflow?.workflowResultData?.workflowStepResults?.find(
    i => i.stepName === 'IDV',
  )
  const processResults = step?.processResults || []

  type ProcessResultMap = Record<
    SupplementaryDataBase['type'],
    ProcessResultObject | undefined
  >
  const processResultMap = processResults.reduce<ProcessResultMap>(
    (acc, curr) => {
      if (curr.supplementaryData?.type) {
        acc[curr.supplementaryData.type] = curr
      }
      return acc
    },
    {} as ProcessResultMap,
  )

  const { IDV_FACIAL_COMPARISON, IDV_FACIAL_LIVENESS } = processResultMap

  const ocrCheckResults = getCheckResults(
    IDV_FACIAL_COMPARISON,
    IDV_FACIAL_LIVENESS,
  )

  const processResultIds = [IDV_FACIAL_COMPARISON, IDV_FACIAL_LIVENESS]
    .map(i => i?.processResultId)
    .filter(Boolean) as string[]

  const gallery = getSelfies(IDV_FACIAL_COMPARISON, entity)
  const manualStatus =
    IDV_FACIAL_COMPARISON?.manualStatus ||
    IDV_FACIAL_LIVENESS?.manualStatus ||
    ''
  const isManualPass = manualStatus === ProcessResultManualStatusEnumKYC.CLEAR
  const isManualFail =
    manualStatus === ProcessResultManualStatusEnumKYC.REJECTED

  const status = IDV_FACIAL_COMPARISON?.result || IDV_FACIAL_LIVENESS?.result
  let workflowStatus = status
    ? PROCESS_RESULT_STATUS_MAP[status]
    : WorkflowStepResultEnum.UNCHECKED

  if (isManualPass) {
    workflowStatus = WorkflowStepResultEnum.PASS
  } else if (isManualFail) {
    workflowStatus = WorkflowStepResultEnum.FAIL
  }

  const requireResolve = isManualPass || !passVariants.includes(workflowStatus)

  return {
    hadOcrChecks,
    status: workflowStatus,
    gallery,
    ocrCheckResults,
    isManualPass,
    isManualFail,
    requireResolve,
    processResultIds,
  }
}

function attachmentToDataUrl(attachment?: DocumentAttachment) {
  if (!attachment?.data.base64 || !attachment.mimeType) return ''
  return getDataFileUrl(attachment.mimeType, attachment.data.base64)
}

export const useNonSelfImageDocuments = (entityId: string) => {
  const { data: entity } = useEntityDataQuery(entityId, 'base64')

  const identityDocs = entity?.individual?.documents?.IDENTITY || []
  const documents = identityDocs.filter(doc => doc.type !== 'SELF_IMAGE')

  const documentThumbs = documents
    .map(doc => {
      const attachment = doc.attachments?.at(0)
      if (!attachment) return null
      return {
        id: attachment.attachmentId,
        url: attachmentToDataUrl(attachment),
        type: attachment.type,
        side: attachment.side,
        createdAt: attachment.createdAt,
      } as GalleryItem
    })
    .filter(Boolean) as GalleryItem[]

  const attachments = documents.map(doc => {
    const attachments = [...(doc.attachments || [])]
    attachments.sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return 0
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })

    // Max to 2 attachments for DL, PP and Medicare only have 1 attachment
    return attachments.splice(0, 2).map(
      attachment =>
        ({
          id: attachment.attachmentId,
          url: attachmentToDataUrl(attachment),
          type: attachment.type,
          side: attachment.side,
          createdAt: attachment.createdAt,
        } as GalleryItem),
    )
  })

  return {
    documents,
    documentThumbs,
    attachments,
  }
}
