import React from 'react'

import { useI18n } from 'shared/i18n'

import { ValueWithTooltip } from './individual-aml-entity-pep-card-text'
import { INDIVIDUAL_AML_RESULT_KEY } from '../../../individual-aml-result.key'
import { individualAmlResultEn } from '../../../locale/individual-aml-result.en'
import { amlEntityCardQa } from '../../../qa/individual-aml-result.qa'
import { useLabelAndSrc } from '../../../state/individual-aml-helper.state'

type Props = {
  entityId: string
  processResultId?: string
  srcLabelKey: 'pepSrcLabel' | 'sanctionSrcLabel' | 'watchlistSrcLabel'
  historyPage?: boolean
}

export function IndividualAmlPepCard({
  entityId,
  processResultId,
  srcLabelKey,
  historyPage,
}: Props) {
  const t = useI18n(INDIVIDUAL_AML_RESULT_KEY, {
    keys: individualAmlResultEn,
  })

  const data = useLabelAndSrc({ t, entityId, processResultId, historyPage })

  const srcLabelGroup = data[srcLabelKey]

  return srcLabelGroup.length ? (
    srcLabelGroup.map(pep => (
      <div
        data-qa={amlEntityCardQa.container}
        className="border border-tertiary-grey-200 rounded-[4px] pt-5 px-6 pb-6"
      >
        <div className="">
          <div
            data-qa={amlEntityCardQa.title}
            className="font-semibold text-sm leading-5 text-tertiary-grey-700 mb-3"
          >
            {pep.title}
          </div>

          <div className="grid grid-flow-col !auto-rows-auto gap-x-3  grid-rows-[repeat(9,_auto)] laptop:grid-rows-[repeat(6,_auto)] laptop:grid-cols-2 w-full">
            {pep.values.map(
              item =>
                item.value && (
                  <div className="flex gap-4 mb-3  shrink" key={item.value}>
                    <div
                      data-qa={amlEntityCardQa.sourceLabel}
                      className="min-w-[140px] font-medium text-sm leading-5 text-tertiary-grey-800"
                    >
                      {item.label}
                    </div>

                    <ValueWithTooltip
                      value={item.value}
                      isUrl={item.isUrl ?? false}
                    />
                  </div>
                ),
            )}
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className="text-tertiary-grey-700">
      {t(`tabMatchCard.noResults.${srcLabelKey}`)}
    </div>
  )
}
