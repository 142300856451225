import React, { useState } from 'react'

import { FrankieButton } from 'frankify/src'

import { DOCUMENT_KEY, documentEn } from 'entities/document/locale/document.en'
import type { IDocumentUploadPreviewProps } from 'entities/document/model/document.model'
import { useFileHandler } from 'entities/document/model/use-file-handler.model'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'

import { PdfPreview } from '../pdf-preview/pdf-preview'

export function DocumentUploadPreview(props: IDocumentUploadPreviewProps) {
  const t = useI18n([DOCUMENT_KEY], { keys: documentEn })
  const { scans, name, uploadedOn } = props

  const [activeIndex, setActiveIndex] = useState(0)
  const { fileUrl, isFileImage, fileName } = useFileHandler(scans, activeIndex)

  const handleFileChange = (next: boolean) => {
    setActiveIndex(prev => {
      const newIndex = next ? prev + 1 : prev - 1
      if (newIndex < 0) return 0
      if (newIndex >= (scans.length || 0)) return (scans.length || 0) - 1
      return newIndex
    })
  }

  return (
    <div className="flex flex-col gap-2 w-[550px] h-[420px]">
      <h1 className="text-tertiary-grey-800 text-lg font-bold">
        {name ?? fileName ?? t('document')}
      </h1>
      <figure className="flex flex-grow-1 items-center justify-center border border-tertiary-grey-300 bg-tertiary-grey-100 rounded-sm relative">
        <div className="border border-tertiary-grey-200 flex bg-mono-white rounded-sm absolute right-2 top-0 z-10">
          <FrankieButton
            intent="subtle"
            size="xs"
            className="border-r border-solid border-tertiary-grey-200 rounded-none"
            singleIcon={{ name: 'mdiChevronLeft' }}
            disabled={activeIndex === 0}
            onClick={() => handleFileChange(false)}
          />
          <FrankieButton
            intent="subtle"
            size="xs"
            disabled={activeIndex === scans.length - 1}
            singleIcon={{ name: 'mdiChevronRight' }}
            onClick={() => handleFileChange(true)}
          />
        </div>

        {isFileImage ? (
          <div className="aspect-w-16 aspect-h-9 relative h-full w-full">
            <img
              data-hj-suppress
              className="absolute inset-0 w-full h-full object-contain"
              src={fileUrl ?? ''}
              alt={name}
              loading="lazy"
              decoding="async"
            />
          </div>
        ) : (
          <div
            data-hj-suppress
            className="overflow-y-auto overflow-x-hidden h-[536px] flex-1 rounded-sm"
          >
            <PdfPreview url={fileUrl ?? ''} pageProps={{ width: 800 }} />
          </div>
        )}
      </figure>
      <p className="text-sm">
        {t('uploadedOn', {
          date: formatDate(
            uploadedOn ?? new Date().toUTCString(),
            DateFormatTypes.fullDateWithTime,
          ),
        })}
      </p>
    </div>
  )
}
