import { useState, useCallback, useEffect } from 'react'

import { getDataFileUrl } from 'shared/file'

import {
  scanDateSorter,
  scanSorter,
  type Scan,
  type IUploadedDocumentData,
} from './document.model'

export const useFileHandler = (
  scans: Scan[],
  activeIndex = 0,
  onChange?: (data: IUploadedDocumentData) => void,
) => {
  const [fileUrl, setFileUrl] = useState<string | null>()
  const [isFileImage, setIsFileImage] = useState(false)
  const [fileName, setFileName] = useState<string>()

  const createSafeFileUrl = (
    file: File | string,
    mimeType?: string,
  ): string | null => {
    try {
      if (file instanceof File) {
        return URL.createObjectURL(file)
      }
      // For base64 data
      return getDataFileUrl(mimeType || '', file)
    } catch (error) {
      console.error('Invalid file data:', error)
      return null
    }
  }

  const handleFileChange = useCallback(
    (file: File, fileId: string) => {
      if (typeof onChange !== 'function') return

      const isImage = !file.type.split('/').includes('pdf')
      setIsFileImage(isImage)

      const url = createSafeFileUrl(file)
      if (url) {
        setFileUrl(url)
      }

      setFileName(file.name)

      onChange({
        fileUploadUuid: fileId,
        mimeType: file.type,
        scanName: file.name,
        scanCreated: new Date().toISOString(),
      })
    },
    [onChange],
  )

  useEffect(() => {
    if (scans.length) {
      const sortedScans = [...scans].sort(scanSorter).sort(scanDateSorter)
      const scan = sortedScans[activeIndex]
      if (!scan.file) return

      setFileName(scan.scanName)
      setIsFileImage(!scan.mimeType.split('/').includes('pdf'))
      setFileUrl(getDataFileUrl(scan.mimeType, scan.file))
    }
  }, [scans, activeIndex])

  return {
    fileUrl,
    isFileImage,
    fileName,
    handleFileChange,
  }
}
