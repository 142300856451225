import React from 'react'

import { Document, Page } from 'react-pdf'

type MediaProps = {
  mimeType: string
  url: string
}

export function Media(props: MediaProps) {
  switch (props.mimeType) {
    case 'application/pdf':
      return (
        <div className="shadow-md rounded-md max-h-96 overflow-hidden">
          <Document file={props.url}>
            <Page
              pageNumber={1}
              width={256}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          </Document>
        </div>
      )
    default:
      return (
        <img
          src={props.url}
          alt=""
          className="object-contain m-auto h-[100%]"
        />
      )
  }
}
