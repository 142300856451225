export const applicantSupportingDocumentsEn = {
  addDocuments: 'Add Documents',
  addDocument: 'Add Document',
  uploadedDocuments: 'Uploaded Documents',
  noDocumentsUploaded: 'No Documents Uploaded',
  seeSupportedDocument:
    'See supported file types <0>here</0>. Max file size of 20MB.',
  documentType: 'Document Type',
  select: 'Select',
  selectType: 'Select type',
  documentStatus: 'Document Status',
  comment: 'Comment',
  addCommentHere: 'Add your comment here',
  save: 'Save',
  back: 'Back',
  remove: 'Remove',

  errorFetchingDoc: 'Error fetching document',
  errorFetchingDocs: 'Error fetching supporting documents',
  errorUploadingDoc: 'Error occurred uploading the document',
  errorUpdatingDoc: 'Error updating the document',
  errorDeletingDoc: 'Error deleting the document',
  errorNoScan: 'No scan available for this document',
  errorFetchingAnalysisStatus: 'Error fetching analysis status',
  errorFetchingAnalysisResult: 'Error fetching analysis result',
  errorTypeUnknown: 'Type Unknown',
  documentUploaded: 'Document has been uploaded',
  documentUpdated: 'Document has been updated',
  documentStatusChange: 'Document Status: Changed to {{status}}',
  documentDeleted: 'Document has been deleted',

  trustDocumentAdded: 'Trust document added',
  uploadTrustDeed: 'Upload trust deed',
  addTrustDeed: 'Add a trust deed document',
  trustDeeds: 'Trust deeds',
  trustDeed: 'Trust Deed',
  trustAnalyzer: 'Trust analyser',
  trustTypeTooltip:
    'Changing the trust type will trigger a new analysis of the trust deed for review.',
  trustType: 'Trust Type',
  addTrustDeedDocument: 'Add a trust deed document',
  startAnalysis: 'Start analysis',
  addFileOnly: 'Add file only',
  trustAnalyzerTypeDescription:
    'Please select the type of trust of the document. This will help extract the trust to the right data format. Editing the trust type post analysis will re-trigger the trust deed analysis.',
  uploadDifferentFile: 'Upload a different file',
  addTrustAnalyzer: 'Trust document added',
  pdfFileTypeOnly: 'PDF file type only. Max file size of 20MB.',
  updatedBy: 'Updated By',
  returnToTrustDeeds: 'Return to trust deeds',
  uploadedOn: 'Uploaded on',
  startAnalyse: 'Start analysing',
  analysisFailed: 'Error trying to trigger the analysis',
  noTrustDeedAvailable: 'No document has been uploaded',
  trustAnalysisProcessingDescription:
    'Analysing data from {{fileName}} in process. This can take up to 4 mins to complete.',

  trustFormLabel: {
    address: {
      country: 'Country',
      propertyName: 'Property Name',
      unitNumber: 'Unit Number',
      streetNumber: 'Street Number',
      streetName: 'Street Name',
      suburb: 'Suburb',
      state: 'State or territory',
      postalCode: 'Postal code',
    },
    name: {
      givenName: 'Given Name',
      middleName: 'Middle Name',
      familyName: 'Family Name',
    },
  },

  trustDeedAnalysisValidation: {
    documentMismatch: {
      title: 'Trust type suspected mismatch',
      description:
        'The trust type you have selected was {{provided}}, but our Trust Analyser suspects it might actually be a {{detected}}. To ensure accurate analysis, would you like to confirm the trust type as {{provided}} or switch to {{detected}} for further evaluation?',
      confirmAsProvided: 'Confirm as {{provided}} Trust',
      switchToDetected: 'Switch to {{detected}} Trust',
    },
    documentFailed: {
      title: 'Analysis on {{fileName}} has failed.',
      description:
        "This might happen because the document isn't on the list of supported types, or there was an issue encountered by the Trust Analyser. Please ensure that your PDF document is a trust deed and it is a supported type.",
    },
    documentUnknown: {
      title: 'Trust type on {{fileName}} could not be detected',
      description:
        "This might happen because the trust type isn't on the list of supported types, or there was an issue encountered by the Trust Analyser. If the trust deed belongs to one of the supported types, please select that trust type and try again.",
    },
    documentAutoDetect: {
      title: 'Trust type auto-detect',
      description:
        'Please review the auto-detected trust type results carefully, as the tool can make mistakes. If you believe the trust type is different, please select the appropriate trust type to rerun the tool for accurate analysis.',
    },
  },

  inputValidation: {
    name: {
      common: 'Please enter a name',
      beneficiary:
        'Please enter name of specified beneficiary or at least one general beneficiary',
    },
    holdingCount: {
      common: 'Unit holder must own at least one unit',
    },
    totalUnits: {
      common: 'Total number of units cannot be 0 or blank',
    },
  },

  trustAnalysisAssociateParty: {
    success: {
      addAssociatedParty: 'Associated party added successfully',
      editAssociatedParty: 'Associated party edited successfully',
      removeAssociatedParty: 'Associated party removed successfully',
      createAssociatedParty: 'Associated party created and added successfully',
    },
  },

  trustDetailReference: {
    name: '[1a]',
    date: '[1b]',
    country: '[1c]',
  },

  returnToTrustDeed: {
    description: 'Are you sure you want to cancel the trust analyser?',
    subDescription: 'All unsaved data will be lost.',
    cancel: 'Return to trust analyser',
    confirm: "Yes I'm sure",
  },

  deleteTrustDeedDocument: {
    description: 'Are you sure you want to delete this Trust deed file?',
    cancel: 'No, cancel',
    confirm: "Yes, I'm sure",
  },

  confirmTrustTypeChange: {
    description:
      'Are you sure you want to change the trust type from {{currentTrustType}} trust to {{updatedTrustType}}?',
    subDescription: 'This will re-run the trust analyser.',
    cancel: 'No, cancel',
    confirm: "Yes I'm sure",
  },

  edit: 'Edit',
  fullName: 'full name',
  totalUnits: 'Total Units',
  holdingCount: 'Number of Units Held',

  confirmAnalysisSuccess: 'Trust document details saved successfully',
  confirmAnalysisFailed: 'Failed to save details. Please try again later',
  type: 'Type',
  settlor: 'Settlor',
  trustee: 'Trustee',
  appointor: 'Appointor',
  protector: 'Protector',
  beneficiary: 'Beneficiary',
  member: 'Member',
  unitHolder: 'Unit Holder',
  generalBeneficiary: 'General Beneficiary',

  trustees: 'Trustees',
  generalBeneficiaries: 'General Beneficiaries',
  beneficiaries: 'Beneficiaries',
  appointors: 'Appointors',
  protectors: 'Protectors',
  members: 'Members',
  unitHolders: 'Unit Holders',

  addSettlor: 'Add Settlor',
  addTrustee: 'Add Trustee',
  group: 'Group',

  add: 'Add',
  role: 'Role',
  trustDetails: 'Trust details',
  specifiedBeneficiary: 'Specified Beneficiary',

  dateOfBirth: 'Date of Birth',
  dateOfEstablishment: 'Date of Establishment',
  nameOfTrust: 'Name of Trust',
  countryOfEstablishment: 'Country / State of Establishment',

  classOfBeneficiaries: 'Class of general Beneficiaries',
  name: 'Name',
  address: 'Address',
  abnOrAcn: 'ABN or ACN',
  principalPlace: 'Principal Place of Business',

  reviewedTrust: 'I have reviewed and accept the data in this trust deed.',
  addAssociateParty: 'Add associate party',
  associatedParty: 'Associate party',
  editTrustData: 'Edit trust data',

  searchAddress: 'Type in an address',

  applicantEntityName: {
    individual: 'Individual',
    organisation: 'Organisation',
  },

  trustDeedStatusType: {
    completed: 'To review',
    failed: 'Analysis failed',
    processing: 'Analysing',
    confirmed: 'Analysed',
    no_status_kvp: 'No analysis',
  },

  trustTypeName: {
    autoDetect: 'Auto-detect trust type',
    discretionary: 'Discretionary',
    unit: 'Unit',
    selfManagedSuperFund: 'Self-managed Super Fund (SMSF)',
  },

  approved: 'Approved',
  needAttention: 'Needs Attention',
  declined: 'Declined',

  loading: {
    uploadingDoc: 'Uploading Document...',
    updatingDoc: 'Updating Document...',
  },

  fetchingDoc: 'Fetching Document...',
  fetchingAnalysisStatus: 'Fetching Analysis Status...',
  fetchingAnalysisResult: 'Fetching Analysis Result...',
  refreshingDocList: 'Refreshing Document List...',
  gettingDocumentDetails: 'Getting document details...',

  deleteDocument: 'Delete Document',
  confirmRemove: 'Are you sure you want to delete this document?',
  delete: 'Delete',
  cancel: 'Cancel',
  document: 'Document',

  notValidDocumentGoBack: 'Not a valid documents, Please go back',

  documentTypeAndName: 'Document Type and Name',
  status: 'Status',
  uploadedDate: 'Uploaded Date',
  upload: 'Upload',
  uploadedBy: 'Uploaded by',
  reviewedBy: 'Reviewed By',
  lastUpdated: 'Last Updated',
  updated: 'Updated',
  uploadedByDetail: 'Uploaded by {{username}} {{date}}',
  loadingRegistryDocuments:
    'The list of registry documents for this organisation is being retrieved.\n Hold tight, in some jurisdictions it can take up to 2 mins to retrieve.',
  documentCatalog: {
    purchasing: 'Purchasing...',
    title: 'Registry document catalogue',
    description:
      'This is a list of registry documents you can purchase. Once purchased, the file will appear on the <0>purchased registry document</0> page. This list was last retrieved on {{lastRetrieveDate}}.',
    descriptionNoRows1:
      'To see the registry documents you can purchase for this entity click on the Retrieve list button.',
    descriptionNoRows2:
      'Every time you click on this button it will fetch the documents list from its respective registry.',
    documentsSelected: '{{number}} documents selected',
    refreshList: 'Refresh list',
    retrieveList: 'Retrieve list',
    purchaseDocument: 'Purchase document',
    document: 'Document',
    purchaseDocumentTitle: 'Confirm your registry documents order',
    noRow:
      'Click on the Retrieve list button to see the list of documents you can purchase for this organisation',
    selectedForPurchase:
      '{{number}} registry documents were selected for purchase',
    tier: 'Tier {{tierCode}}',
    noRows:
      'Click on the Retrieve list button to see the list of documents you can purchase for this organisation',
    retrievingError: 'The registry document list for {{businessName}} failed',
    retrievingSuccess:
      'The registry document list for {{businessName}} is ready to view',
    tableColumn: {
      document: 'DOCUMENT',
      pricingTier: 'PRICING TIER',
      status: 'STATUS',
      issue: 'ISSUE',
    },
    action: {
      retry: 'Retry request',
    },
    banner: {
      heading: 'The document processing is currently in progress',
      description:
        'The documents have been ordered and are awaiting retrieval. Check back again soon or refresh the page to view.',
    },
  },
  bulkActionMessage: {
    success: {
      text: 'Successfully purchased',
      subtext: '{{total}} reports(s) were successfully purchased.',
    },
    info: {
      text: 'Partially purchased',
      subtext:
        '{{success}} out of {{total}} reports were successfully purchased. {{fail}} reports(s) failed to purchase.',
    },
    error: {
      text: 'Unable to resolve',
      subtext: '{{total}} reports(s) failed to purchase.',
    },
  },
  statuses: {
    failed: 'Failed',
    purchased: 'Purchased',
    processing: 'Processing',
  },
  purchasedDocuments: {
    heading: 'Purchased registry documents',
    tableColumn: {
      document: 'DOCUMENT',
      status: 'STATUS',
      issue: 'ISSUE',
      purchased: 'PURCHASED',
      orderedBy: 'ORDERED BY',
    },
    noDocuments: 'No document has been purchased',
  },
}
