import React, { useEffect, useMemo } from 'react'

import { FrankieTextField } from 'frankify/src'

import { INDIVIDUAL_PROFILE_KEY } from 'features/individual-profile/individual-profile.keys'
import { individualProfileEn } from 'features/individual-profile/locale/new-profile.en'
import { IndividualProfileInputTypes } from 'features/individual-profile/model/form.model'

import { CountryAlpha3CodeTypes } from 'entities/country'
import { KycDocumentCategoryTypes } from 'entities/document'

import { getError } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { individualProfileVueMigratedQa } from '../../../../qa/individual-profile.qa'
import { DocumentCategory } from '../document-category/document-category'
import { DocumentsSubFormProps } from '../document-sub-forms.types'

export function PassportForm({
  form,
  idx,
  wrapperClasses,
}: DocumentsSubFormProps) {
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    unregister,
    getValues,
  } = form
  const t = useI18n([INDIVIDUAL_PROFILE_KEY], { keys: individualProfileEn })

  useEffect(() => {
    setValue(
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentCategory}`,
      KycDocumentCategoryTypes.PRIMARY_PHOTOGRAPHIC as never,
    )
  }, [idx, setValue])

  const idNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.IdNumber}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  const documentNumberFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentNumber}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  const idExpiryFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Passport}.${IndividualProfileInputTypes.IdExpiry}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  const selectedCountry: string = watch(
    `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Country}`,
  )

  const homeCountryFieldName = useMemo(
    () =>
      `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.HomeCountryFullName}` as IndividualProfileInputTypes.Document,
    [idx],
  )

  const isIndia = useMemo(
    () => selectedCountry === CountryAlpha3CodeTypes.INDIA,
    [selectedCountry],
  )

  const showPassportExpiry = useMemo(
    () =>
      selectedCountry === CountryAlpha3CodeTypes.NEW_ZEALAND &&
      getValues(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.Passport}.${IndividualProfileInputTypes.IdExpiry}`,
      ) !== '0001-01-01',
    [selectedCountry, idx, getValues],
  )

  const documentNumberRegister = useMemo(
    () =>
      isIndia &&
      register(
        `${IndividualProfileInputTypes.Document}.${idx}.${IndividualProfileInputTypes.DocumentNumber}`,
        { required: true },
      ),
    [idx, isIndia, register],
  )

  // unregister id number and document number because used in multiple components
  useEffect(
    () => () => {
      unregister(idNumberFieldName)
      unregister(documentNumberFieldName)
    },
    [idx, unregister, idNumberFieldName, documentNumberFieldName],
  )

  if (selectedCountry === CountryAlpha3CodeTypes.INDIA) {
    return (
      <div className={wrapperClasses}>
        <FrankieTextField
          className="basis-[32%]"
          label={t('documentForm.passportNumber')}
          placeholder={t('documentForm.passportNumber')}
          error={!!getError(idNumberFieldName, errors)}
          {...register(idNumberFieldName, {
            required: true,
          })}
          testId={{
            input: individualProfileVueMigratedQa.document.idNumberField,
          }}
        />
        <FrankieTextField
          className="basis-[32%]"
          label={t('documentForm.fileNumber')}
          placeholder={t('documentForm.fileNumber')}
          error={!!getError(documentNumberFieldName, errors)}
          testId={{
            input: individualProfileVueMigratedQa.document.documentNumber,
          }}
          {...documentNumberRegister}
        />
        <DocumentCategory
          disabled
          className="basis-[32%]"
          form={form}
          idx={idx}
        />
      </div>
    )
  }
  return (
    <div className={wrapperClasses}>
      <FrankieTextField
        className="basis-[32%]"
        label={t('documentForm.passportNumber')}
        placeholder={t('documentForm.passportNumber')}
        error={!!getError(idNumberFieldName, errors)}
        {...register(idNumberFieldName, {
          required: true,
        })}
        testId={{
          input: individualProfileVueMigratedQa.document.idNumberField,
        }}
      />

      {selectedCountry === CountryAlpha3CodeTypes.CHINA && (
        <FrankieTextField
          className="basis-[32%]"
          label={t('documentForm.homeCountryFullName')}
          placeholder={t('documentForm.homeCountryFullNameChina')}
          testId={{
            input: individualProfileVueMigratedQa.document.homeCountryFullName,
          }}
          error={!!getError(homeCountryFieldName, errors)}
          {...register(homeCountryFieldName, {
            required: true,
            shouldUnregister: true,
          })}
        />
      )}

      {showPassportExpiry && (
        <FrankieTextField
          type="date"
          className="basis-[32%]"
          label={t('documentForm.dateOfExpiry')}
          {...register(idExpiryFieldName, { required: true })}
          placeholder={t('documentForm.dateOfExpiry')}
          error={!!getError(idExpiryFieldName, errors)}
          testId={{
            input: individualProfileVueMigratedQa.document.idExpiry,
          }}
        />
      )}
    </div>
  )
}
