import { bffClient, IClient } from 'shared/client'

import { ApplicantAuditRecordResponse } from '../model/applicant-audit-report.model'

export class ApplicantAuditReportAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async downloadAuditReport(entityId: string) {
    return this.client.get<BlobPart>(
      `data/v1/audit-logs.pdf?entityId=${entityId}`,
      {
        responseType: 'arraybuffer',
        params: {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    )
  }

  async getAuditRecords(entityId: string) {
    return this.client.get<ApplicantAuditRecordResponse>(
      `data/v1/audit-logs?entityId=${entityId}`,
      {
        params: {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    )
  }
}
export const applicantAuditReportApi = new ApplicantAuditReportAPI(bffClient)
