export const individualAmlResultEn = {
  individualAmlResolveSingleSummary: {
    entitySummary: 'AML search',
    fullName: 'Full Name',
    aliases: 'Aliases',
    yearOfBirth: 'Year of Birth',
    countries: 'Countries',
    residentialAddress: 'Residential Address',
    previousAddresses: 'Previous Residential Address',
    fuzziness: 'Fuzziness',
    vendorId: 'Vendor Case ID',
    associates: 'Associates',
    address: 'Address',
    country: 'Country',
    countryOfResidence: 'Countries',
  },
  individualAmlMatchData: {
    title: 'Match data',
    matchStrength: '{{matchStrength}}% match strength',
  },
  tabsItem: {
    pep: 'PEP',
    sanctions: 'Sanctions',
    watchlist: 'Watchlist',
    adverseMedia: 'Adverse Media',
    otherDataSources: 'Other data sources',
    pep_other: 'PEP ({{count}})',
    sanctions_other: 'Sanctions ({{count}})',
    watchlist_other: 'Watchlist ({{count}})',
    adverseMedia_other: 'Adverse Media ({{count}})',
    otherDataSources_other: 'Other data sources ({{count}})',
    pep_one: 'PEP ({{count}})',
    sanctions_one: 'Sanctions ({{count}})',
    watchlist_one: 'Watchlist ({{count}})',
    adverseMedia_one: 'Adverse Media ({{count}})',
    otherDataSources_one: 'Other data sources ({{count}})',
  },
  tabMatchCard: {
    noResults: {
      pepSrcLabel: 'There is no PEP available for this entity',
      sanctionSrcLabel: 'There are no Sanctions available for this entity',
      watchlistSrcLabel: 'There are no Watchlist available for this entity',
      mediaSrcLabel: 'There is no adverse media available for this entity',
      otherSrcLabel:
        'There are no supporting documents available for this entity',
    },

    countryCodes: 'Country Codes',
    country: 'Country',
    srcName: 'Source name',
    level: 'Level',
    srcUrl: 'Source URL',
    srcReason: 'Source Reason',
    politicianPosition: 'Political Position',
    srcPosition: 'Source Position',
    startData: 'Source Listing Started',
    endDate: 'Source Listing Ended',
    imageUrl: 'Image URL',
    referenceDocs: 'Reference Docs',
    politicalPosition: 'Political Position',
    positionDescription: 'Position Description',
  },
}
