import React from 'react'

import { DocumentInformation } from 'entities/entity'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { GalleryImage } from 'shared/document-thumbs/ui/document-thumbs'
import { FrankieUtilityImg } from 'shared/frankie-image'
import { useI18n } from 'shared/i18n'
import { GalleryItem } from 'shared/image-gallery'
import { TrackingEventsTypes } from 'shared/tracking'

import { DOCUMENT_F2_KEY, documentF2En } from '../../locale/document-f2.en'
import { documentIdTypesOption } from '../../model/document-id-types.model'

type Props = {
  document: DocumentInformation
  className?: string
  extraData: { label: string; value?: string }[]
  trackingEvents?: {
    gallery: TrackingEventsTypes[]
    rotate: TrackingEventsTypes
    zoom: TrackingEventsTypes
    show: TrackingEventsTypes
  }
}

type DocumentOverlayProps = {
  document: Props['document']
  extraData: Props['extraData']
  trackingEvents: Props['trackingEvents']
  urls: GalleryImage[]
  index?: number
}

export function DocumentOverlay(props: DocumentOverlayProps) {
  const { document, index, extraData, urls, trackingEvents } = props

  const t = useI18n(DOCUMENT_F2_KEY, { keys: documentF2En })

  const { tKey: documentLabelTypes = '' } =
    documentIdTypesOption.find(({ value }) => value === document.type) ?? {}

  return (
    <div className="flex flex-wrap max-w-[850px] gap-x-6 gap-y-4 min-h-[450px]">
      <h2 className="text-xl basis-full font-semibold">
        {documentLabelTypes ? t(documentLabelTypes) : document.type}
      </h2>

      <div className="flex flex-col gap-y-3">
        <p className="text-tertiary-grey-500 text-sm leading-5">
          {t('uploadedAt', {
            date: formatDate(
              document.createdAt ?? new Date().toUTCString(),
              DateFormatTypes.DateNumbersSlash,
            ),
            time: formatDate(
              document.createdAt ?? new Date().toUTCString(),
              DateFormatTypes.Time24HoursWithSeconds,
            ),
          })}
        </p>
        <FrankieUtilityImg
          defaultIdx={index}
          gallery={urls as GalleryItem[]}
          className="!w-[610px] !h-[360px]"
          trackingEvents={trackingEvents}
        />
      </div>
      <div className="min-w-[200px] max-w-[200px] max-h-[495px] overflow-y-auto">
        {extraData.map(({ label, value }) => (
          <React.Fragment key={label}>
            <h3 className="mt-3 font-medium text-tertiary-grey-800">{label}</h3>
            <p data-hj-suppress className="font-normal text-tertiary-grey-700">
              {value ?? '-'}
            </p>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
