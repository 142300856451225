import React from 'react'

import { useEntityDataQuery, useGetWorkflowEventsData } from 'entities/entity'
import {
  ProcessResultManualStatusEnumKYC,
  ProcessResultObject,
  SupplementaryDataBase,
  WorkflowStepResultEnum,
} from 'entities/entity/model/entity.model'

import { IdvDocument, makeIdvDocuments } from '../../model/idv-documents.model'
import {
  getStatusFromProcessResult,
  passVariants,
} from '../../model/individual-idv-check.model'
import { idvCheckFullReport } from '../../model/mock-idv-data.model'

type Args = {
  entityId: string
  idx: number
}

export type DocumentInfo = IdvDocument
export type DocumentCheckReport = (typeof idvCheckFullReport)[number]

export const useIndividualIdvCheckState = ({ entityId, idx }: Args) => {
  const { data: workflowEvent } = useGetWorkflowEventsData({ entityId })
  const { data: entity } = useEntityDataQuery(entityId, 'base64')

  const documents = React.useMemo(
    () => makeIdvDocuments(entity, workflowEvent),
    [entity, workflowEvent],
  )

  const workflow = workflowEvent?.workflowSummaries?.at(0)

  const hadIdvChecks = workflow?.steps?.order?.includes('IDV')

  const step = workflow?.workflowResultData?.workflowStepResults?.filter(
    i => i.stepName === 'IDV',
  )[idx]
  type ProcessResultMap = Record<
    SupplementaryDataBase['type'],
    ProcessResultObject | undefined
  >
  const processResultMap = (
    step?.processResults || []
  ).reduce<ProcessResultMap>((acc, curr) => {
    if (curr.supplementaryData?.type) {
      acc[curr.supplementaryData.type] = curr
    }
    return acc
  }, {} as ProcessResultMap)

  const { IDV_DOCUMENT, IDV_OCR, IDV_OCR_COMPARISON } = processResultMap
  const processResultIds = [
    IDV_DOCUMENT?.processResultId,
    IDV_OCR?.processResultId,
    IDV_OCR_COMPARISON?.processResultId,
  ].filter(Boolean) as string[]

  const manualStatus =
    IDV_DOCUMENT?.manualStatus ||
    IDV_OCR?.manualStatus ||
    IDV_OCR_COMPARISON?.manualStatus ||
    ''
  const isManualPass = manualStatus === ProcessResultManualStatusEnumKYC.CLEAR
  const isManualFail =
    manualStatus === ProcessResultManualStatusEnumKYC.REJECTED

  let status = getStatusFromProcessResult(
    'IDV_OCR_COMPARISON',
    step?.processResults,
  )

  if (isManualPass) {
    status = WorkflowStepResultEnum.PASS
  } else if (isManualFail) {
    status = WorkflowStepResultEnum.FAIL
  }

  const requireResolve = isManualPass || !passVariants.includes(status)

  return {
    hadIdvChecks,
    status,
    documents,
    isManualPass,
    isManualFail,
    requireResolve,
    processResultIds,
  }
}
