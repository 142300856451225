import { useReducer } from 'react'

type State = {
  activeIndex: number
  activeDocumentIndex: number
  activeSubDocumentIndex: number
}

type Action =
  | { type: 'SET_ACTIVE_INDEX'; payload: number }
  | { type: 'SET_ACTIVE_DOCUMENT_INDEX'; payload: number }
  | { type: 'SET_ACTIVE_SUB_DOCUMENT_INDEX'; payload: number }

// Define the initial state
const initialState: State = {
  activeIndex: 0,
  activeDocumentIndex: 0,
  activeSubDocumentIndex: 0,
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_ACTIVE_INDEX':
      return { ...state, activeIndex: action.payload }
    case 'SET_ACTIVE_DOCUMENT_INDEX':
      return { ...state, activeDocumentIndex: action.payload }
    case 'SET_ACTIVE_SUB_DOCUMENT_INDEX':
      return { ...state, activeSubDocumentIndex: action.payload }
    default:
      return state
  }
}

// Custom hook
export const useActiveDocIndex = (defaultIndex: number) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    activeIndex: defaultIndex,
  })

  const setActiveIndex = (index: number) =>
    dispatch({ type: 'SET_ACTIVE_INDEX', payload: index })
  const setActiveDocumentIndex = (index: number) =>
    dispatch({ type: 'SET_ACTIVE_DOCUMENT_INDEX', payload: index })
  const setActiveSubDocumentIndex = (index: number) =>
    dispatch({ type: 'SET_ACTIVE_SUB_DOCUMENT_INDEX', payload: index })

  return {
    state,
    setActiveIndex,
    setActiveDocumentIndex,
    setActiveSubDocumentIndex,
  }
}
