import React from 'react'

import { SxProps } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridRow,
  GridRowProps,
} from '@mui/x-data-grid-pro'
import { Link } from 'react-router-dom'

import { FrankieIcon, FrankieLoader } from 'frankify/src'

import {
  ApplicantIssueActionBadge,
  useApplicantPaths,
} from 'entities/applicant'

import { Show } from 'shared/hoc'
import { useI18n } from 'shared/i18n'

import { APPLICANT_BUSINESS_OWNERSHIP_KEY } from '../../applicant-business-ownership.key'
import { applicantBusinessOwnershipEn } from '../../locale/applicant-business-ownership.en'
import {
  ApplicantOwnershipHolderRecord,
  ApplicantOwnershipHolderRecordProperties,
  ApplicantOwnershipHolderTypes,
} from '../../model/applicant-business-ownership.model'
import { businessOwnershipDataGridHelperQa } from '../../qa/applicant-business-ownership.qa'
import { BusinessOwnershipDataGridActionMenu } from '../business-ownership-data-grid-action-menu/business-ownership-data-grid-action-menu'

export type BusinessOwnershipGridName = `BO_${number}${'A' | 'B' | 'C' | ''}`

export const businessOwnershipGridName = {
  ubos: 'BO_1', // Ultimate Beneficial Owners (Individuals)
  otherOwners: 'BO_2', // Other Owners
  nonIndividuals: 'BO_3', // Non-Individual Owners
  officeHolders: 'BO_4', // Officeholders & Other Roles
  shareHolders: 'BO_5A', // Shareholders - Share Capital
  shareCapitalGroup: 'BO_5B', // Issued Share Capital - Share Capital
  linkedCompanies: 'BO_6', // Linked Companies
  associatedParties: 'BO_7', // Associated Parties
  blockingEntities: 'BO_8', // Blocking Entities
} satisfies Record<ApplicantOwnershipHolderTypes, BusinessOwnershipGridName>

export type BusinessOwnershipDataGridColumn =
  (GridColDef<ApplicantOwnershipHolderRecord> & {
    field: ApplicantOwnershipHolderRecordProperties
    showInGrid: (typeof businessOwnershipGridName)[keyof typeof businessOwnershipGridName][]
  })[]

export const BUSINESS_OWNERSHIP_DATA_GRID_MIN_WIDTH = 800

export const businessOwnershipDataGridCellWidth = (
  width: number,
  noFlex = false,
) => ({
  minWidth: width,
  ...(noFlex
    ? { maxWidth: width }
    : { flex: width / BUSINESS_OWNERSHIP_DATA_GRID_MIN_WIDTH }),
})

export type RowProps = GridRowProps & { row: ApplicantOwnershipHolderRecord }

export function BusinessOwnershipDataGridRow(props: RowProps) {
  const { entityId } = props.row

  const { getApplicantPath } = useApplicantPaths()

  if (!entityId) {
    return <GridRow className="!pointer-events-none" {...props} />
  }

  return (
    <Link
      to={getApplicantPath('generalInformation', entityId)}
      className="!transition-none hover:!transition-none hover:!text-current hover:!text-mono-black"
    >
      <GridRow {...props} />
    </Link>
  )
}

export function BusinessOwnershipDataGridCell({
  row,
  colDef,
  applicantId,
}: GridRenderCellParams<ApplicantOwnershipHolderRecord> & {
  applicantId: string
}) {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  const field = colDef.field as ApplicantOwnershipHolderRecordProperties

  return (
    <Show>
      <Show.When isTrue={field === 'type'}>
        <FrankieIcon
          className="text-tertiary-grey-400"
          name={
            row.type === 'individual'
              ? 'mdiAccountOutline'
              : 'mdiOfficeBuildingOutline'
          }
        />
      </Show.When>

      <Show.When isTrue={field === 'name'}>
        <div className="font-semibold">
          {row.originalName && (
            <div className="text-tertiary-grey-400">
              {t('dataGridFields.updatedName')}
            </div>
          )}
          <div>{row.name}</div>
          {row.originalName && (
            <div className="text-tertiary-grey-400">
              {t('dataGridFields.registryName')}
            </div>
          )}
          {row.originalName && <div>{row.originalName}</div>}
          {row.customerReference && <div>{row.customerReference}</div>}
        </div>
      </Show.When>

      <Show.When isTrue={field === 'profileStatus'}>
        <ApplicantIssueActionBadge type={row.profileStatus} />
      </Show.When>

      <Show.When isTrue={field === 'issues'}>
        <div className="flex flex-wrap gap-[5px]">
          {row.issues.map(issue => (
            <ApplicantIssueActionBadge
              className="!font-semibold !p-1 max-w-min"
              type={issue}
            />
          ))}
        </div>
      </Show.When>

      <Show.When isTrue={field === 'actions'}>
        <BusinessOwnershipDataGridActionMenu
          applicantId={applicantId}
          className="ml-auto"
          rowData={row}
        />
      </Show.When>

      <Show.Else>
        <div className="font-semibold">{row[field]}</div>
      </Show.Else>
    </Show>
  )
}

export function BusinessOwnershipDataGridLoadingOverlay() {
  const t = useI18n([APPLICANT_BUSINESS_OWNERSHIP_KEY], {
    keys: applicantBusinessOwnershipEn,
  })

  return (
    <div className="flex align-center justify-center flex-col w-full h-full bg-mono-white opacity-80">
      <FrankieLoader
        label={t('loading.default')}
        loading
        className="text-tertiary-grey-800 text-md font-semibold min-h-[250px]"
        size="sm"
        testId={{ loader: businessOwnershipDataGridHelperQa.loader }}
      />
    </div>
  )
}

export const businessOwnershipDataGridSx: SxProps = {
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    height: '100%',
  },
  '& .MuiDataGrid-columnHeaders': {
    lineHeight: 'unset !important',
    borderRadius: '0 !important',
  },
  '& .MuiDataGrid-row--lastVisible': {
    '& .MuiDataGrid-cell': { border: '0px !important' },
  },

  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: '0 14px',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'inherit',
    whiteSpace: 'pre-line',
  },
  '& .MuiDataGrid-row': {
    lineHeight: 'unset !important',
  },
  '& .MuiDataGrid-cell': {
    maxHeight: 'unset !important',
    p: '20px 14px',
  },
  '& .MuiDataGrid-cellContent': {
    whiteSpace: 'normal',
    color: 'inherit',
  },
  '& .MuiDataGrid-overlayWrapperInner': {
    p: '20px',
  },
}
