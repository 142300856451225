import { RegisterOptions } from 'react-hook-form'

import {
  CommonTrustFormTypes,
  TrustFormTypes,
} from '../../../model/applicant-supporting-trust-deed.model'

export const nameValidation = (
  shouldValidate: boolean,
  options: { formKey: CommonTrustFormTypes; beneficiaryHasValue: boolean },
  errorMessage: { [key: string]: string },
): RegisterOptions => ({
  validate: (value: string) => {
    const { formKey, beneficiaryHasValue } = options
    const isGeneral = formKey === TrustFormTypes.GeneralBeneficiary
    const isSpecified = formKey === TrustFormTypes.SpecifiedBeneficiary
    const isBeneficiary = isGeneral || isSpecified

    const message = isBeneficiary
      ? errorMessage.beneficiary
      : errorMessage.common

    // check if one of general beneficiary or specified beneficiary has value
    if (isBeneficiary && shouldValidate && !value) {
      if (beneficiaryHasValue) return true
      return message
    }

    if (shouldValidate && !value) {
      return message
    }
    return true
  },
})

export const holdingCountValidation = (errorMessage: {
  common: string
}): RegisterOptions => ({
  required: errorMessage.common,
  min: {
    value: 1,
    message: errorMessage.common,
  },
  valueAsNumber: true,
})

export const totalUnitsValidation = (errorMessage: {
  common: string
}): RegisterOptions => ({
  required: errorMessage.common,
  min: {
    value: 1,
    message: errorMessage.common,
  },
  valueAsNumber: true,
})
