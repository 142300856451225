import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import { FrankieTextField } from 'frankify/src'

import { AbrOrganisationChecks } from 'features/organisation-audit'
import { ORGANISATION_SEARCH_KEY } from 'features/organisation-search/organisation-search.key'

import { isAcn, isAbn } from 'entities/organisation'

import { getError } from 'shared/form'
import { useI18n } from 'shared/i18n'

import { ABRCompany } from '../../model/abr-search-model'

type FormFields = {
  companyName: string
  abnOrAcn: string
}

export function ManualAddAustralianOrganisation() {
  const t = useI18n([ORGANISATION_SEARCH_KEY])
  const {
    register,
    watch,
    formState: { errors },
  } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      companyName: '',
      abnOrAcn: '',
    },
  })

  const companyNameRegister = register('companyName')
  const abnOrAcnRegister = register('abnOrAcn', {
    validate: value => !value || (!!value && (isAbn(value) || isAcn(value))),
  })

  const { companyName, abnOrAcn } = watch()

  const [orgInfo, setOrgInfo] = useState<Partial<ABRCompany>>({
    abn: null,
    acn: null,
    name: '',
  })

  useEffect(() => {
    if (abnOrAcn || companyName) {
      const trimmedAbnOrAcn = abnOrAcn.replace(/\s/g, '')
      // EITHER ACN OR ABN, NOT BOTH
      // SINCE THIS IS A SINGLE INPUT
      if (isAcn(abnOrAcn)) {
        setOrgInfo({
          name: companyName,
          acn: trimmedAbnOrAcn,
          abn: '',
        })
      } else if (isAbn(abnOrAcn)) {
        setOrgInfo({
          name: companyName,
          abn: trimmedAbnOrAcn,
          acn: '',
        })
      } else {
        setOrgInfo({
          name: companyName,
          abn: null,
          acn: null,
        })
      }
    }
  }, [abnOrAcn, companyName])

  return (
    <div>
      <div id="text-inputs" className="flex flex-row justify-between">
        <FrankieTextField
          {...companyNameRegister}
          className="!w-[559px]"
          label={t('manuallyAdd.organisationNameLabel')}
          type="text"
        />
        <FrankieTextField
          {...abnOrAcnRegister}
          className="!w-[327px]"
          label={t('manuallyAdd.abnOrAcnLabel')}
          type="number"
          error={!!getError('abnOrAcn', errors)}
        />
      </div>
      {Boolean(companyName.length) && !errors.abnOrAcn && (
        <div className="mt-8 pb-16">
          <AbrOrganisationChecks abrOrganisation={orgInfo} />
        </div>
      )}
    </div>
  )
}
