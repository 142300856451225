import React, { useEffect, useMemo } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import {
  Banner,
  FrankieBadge,
  FrankieButton,
  FrankieLoader,
} from 'frankify/src'

import { IndividualSendVerificationLinkForm } from 'features/individual-send-verification-link'

import { ApplicantId } from 'entities/applicant'
import { CommentHOC, CommentsTypes } from 'entities/comments'
import {
  FlowIdTypes,
  useFrankie2R2Customer,
  useGetWorkflowEventsData,
} from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useHasPermission } from 'entities/session'

import { Menu } from 'shared/components'
import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { notification } from 'shared/notification'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { AmlScreeningEvents } from './aml-screening-events/aml-screening-events'
import { RiskLevelComponent } from './common/risk-level/risk-level'
import { ManualStatusChangeForm } from './entity-manual-event-form/entity-manual-event-form'
import { EntityRiskScore } from './entity-risk-score/entity-risk-score'
import { EntityVerificationEvents } from './entity-verification-events/entity-verification-events'
import { EntityVerificationReverify } from './entity-verification-reverify/entity-verification-reverify'
import { EntityVerificationReverifyModal } from './entity-verification-reverify/entity-verification-reverify-modal/entity-verification-reverify-modal'
import { workflowEventsEn } from '../locale/workflow-events.en'
import { getWorkflowEventResultConfig } from '../model/applicant-workflow-events-mapping.model'
import { MutateManualStatusType } from '../model/applicant-workflow-events.model'
import { useExecuteWorkflowMutation } from '../mutation/execute-workflow.mutation'
import { useUpdateWorkflowStatus } from '../mutation/update-workflow.mutation'
import { applicantWorkflowEventsQa } from '../qa/applicant-workflow-events.qa'
import { useAmlUnreviewd } from '../state/get-unreview-aml-events.state'
import { useGetWorkFlowDataWithSources } from '../state/get-workflow-events.query'
import { useSendLinkBannerState } from '../state/send-link-banner.state'
import { WORKFLOW_EVENTS_KEY } from '../workflow-events.key'

type Props = {
  entityId: ApplicantId
  editEntityPathWithID: string
}

// eslint-disable-next-line complexity
export function ApplicantWorkflowEvents({
  entityId,
  editEntityPathWithID,
}: Props) {
  const { state } = useLocation()
  const navigate = useNavigate()
  const isFrankie2R2 = useFrankie2R2Customer()
  const [createOverlay, closeOverlay] = useOverlay()
  const { isAmlYetToReviewed } = useAmlUnreviewd({ entityId })

  const { data, isFetching } = useGetWorkflowEventsData({
    entityId,
  })

  const t = useI18n([WORKFLOW_EVENTS_KEY], {
    keys: workflowEventsEn,
  })

  const { mutate: executeWorkflow, isLoading: isExecutingWorkflow } =
    useExecuteWorkflowMutation({ entityId })

  const { editIndividualPermission } = useHasPermission({
    editIndividualPermission: PermissionTypes.ManualIdvUpdateAll,
  })

  const {
    closeSendLinkBanner,
    showSendLinkBanner,
    canSendLink,
    hasTwilioEnabled,
  } = useSendLinkBannerState(entityId)

  const { isLoading, isNotUnchecked, isArchived } =
    useGetWorkFlowDataWithSources({
      entityId,
    })

  const {
    mutate,
    status: workflowStatus,
    isLoading: isUpdateStatusLoading,
  } = useUpdateWorkflowStatus({
    entityId,
  })

  /**
   * Get the workflow event result
   * @returns {WorkflowExecutionResultEnum}
   */
  const workflowEventResult = useMemo(
    () => data?.workflowSummaries?.at(0),
    [data?.workflowSummaries],
  )
  /**
   * Get the risk level
   * @returns {RiskLevel}
   */
  const riskLevel = useMemo(
    () => workflowEventResult?.riskAssessment?.riskLevel,
    [workflowEventResult],
  )
  const hasRiskStep = useMemo(
    () =>
      !!workflowEventResult?.riskAssessment?.riskLevel ||
      !!workflowEventResult?.riskAssessment?.riskScore,
    [workflowEventResult],
  )

  const updateWorkflowStatus = (payload: MutateManualStatusType) => {
    mutate(payload)
    closeOverlay()
  }

  function handleSelect(value: string) {
    createOverlay(
      <ManualStatusChangeForm
        options={getWorkflowEventResultConfig(t, workflowEventResult).options}
        statusValue={value}
        entityData={data!}
        closeOverlay={closeOverlay}
        updateWorkflowStatus={updateWorkflowStatus}
        isAmlYetToReviewed={isAmlYetToReviewed}
      />,
    )
  }

  const handleRiskScoreClick = () => {
    createOverlay(<EntityRiskScore entityId={entityId} />, {
      asSideDrawer: true,
      closeButtonClassName: '!top-8 !right-8',
      wrapperClassName: '[&>div]:!rounded-none ',
    })
  }

  useEffect(() => {
    if (state) {
      const link = document.createElement('a')
      link.href = state as unknown as string
      link.click()
    }
  }, [state])

  if (isExecutingWorkflow || isUpdateStatusLoading) {
    return (
      <FrankieLoader
        size="md"
        fullscreen
        className="!z-[100]"
        label={t('executeWorkflow.loading')}
        loading={isExecutingWorkflow || isUpdateStatusLoading}
      />
    )
  }

  if (workflowStatus === 'success' && isFetching) {
    notification.success(t('notification.success'))
  }
  if (workflowStatus === 'error' && isFetching) {
    notification.error(t('notification.error'))
  }

  const attempt = data?.workflowSummaries?.at(0)?.workflowAttempts ?? 0

  if (!isNotUnchecked && isFrankie2R2 && !(isFetching || isLoading)) {
    const button = editIndividualPermission
      ? {
          name: 'verify-profile',
          className: '!mb-0',
          label: t('banner.button'),
          onClick: () =>
            createOverlay(
              <EntityVerificationReverifyModal
                showWorkflow
                onConfirm={executeWorkflow}
                defaultWorkflow={data?.currentWorkflowName}
              />,
              { className: '!p-5 ', closeButtonClassName: '!top-5 !right-5' },
            ),
        }
      : undefined
    const buttonSecondary = editIndividualPermission
      ? {
          name: 'verify-profile',
          className: '!mb-0',
          label: t('executeWorkflow.edit'),
          onClick: () => navigate(editEntityPathWithID),
        }
      : undefined

    return (
      <div>
        <Banner
          heading={t('banner.title')}
          theme="warning"
          buttonSecondary={buttonSecondary}
          button={button}
        >
          <p>{t('banner.description')}</p>
          {!editIndividualPermission && (
            <div className="mt-3 text-tertiary-grey-500 text-xs">
              {t('banner.noPermission')}
            </div>
          )}
        </Banner>
      </div>
    )
  }

  const handleSendLink = () => {
    trackingManager.trackWithProps(
      TrackingEventsTypes.IndividualSendLinkWorkflowEventClick,
      {
        entityId,
        flowId: FlowIdTypes.IDV,
      },
    )

    createOverlay(
      <IndividualSendVerificationLinkForm
        entityId={entityId}
        defaultFlowId={FlowIdTypes.IDV}
      />,
    )
  }

  return (
    <FrankieLoader
      className="!h-max flex flex-col gap-6 [&>div]:!grow-0"
      loading={isFetching || isLoading}
      loaderClassName="!z-[30]"
    >
      <CommentHOC
        entityId={entityId}
        commentType={CommentsTypes.WORKFLOW}
        className="!h-min"
      >
        <div className="!h-max flex flex-col gap-6 [&>div]:!grow-0 ">
          {showSendLinkBanner && hasTwilioEnabled && isFrankie2R2 && (
            <Banner
              onClose={closeSendLinkBanner}
              heading={t('sendLinkBanner.title')}
              description={t('sendLinkBanner.description')}
              theme="warning"
              className="!mb-1"
              button={
                canSendLink
                  ? {
                      name: 'send-link',
                      className: '!mb-0',
                      label: t('sendLinkBanner.button'),
                      onClick: handleSendLink,
                    }
                  : undefined
              }
            />
          )}

          <div
            className="overflow-auto"
            data-qa={applicantWorkflowEventsQa.container}
          >
            <div className="h-auto">
              {!!data?.workflowSummaries?.length && (
                <div className="flex justify-between mb-1">
                  <div className="flex flex-col gap-[6px]">
                    <div className="flex gap-3 items-center">
                      <span
                        className="text-xl font-bold text-tertiary-grey-800 leading-7"
                        data-qa={applicantWorkflowEventsQa.workflowName}
                      >
                        {data.currentWorkflowName}
                      </span>
                      <FrankieBadge
                        theme="blue"
                        text={`${attempt} ${t('header.attempt', {
                          s: attempt !== 1 ? 's' : '',
                        })}`}
                        testId={{ badge: applicantWorkflowEventsQa.attempt }}
                        className="!truncate"
                      />
                    </div>
                    <div className="flex flex-col text-tertiary-grey-500 text-xs leading-4">
                      <div data-qa={applicantWorkflowEventsQa.eventId}>
                        {`${t('header.eventId')}:
                  ${data.currentWorkflowId ?? ''}`}
                      </div>
                      <div data-qa={applicantWorkflowEventsQa.createdAt}>
                        {`${t('header.createdAt')}  ${formatDate(
                          data.createdAt as string,
                          DateFormatTypes.shortDateWithTime,
                        )}`}
                      </div>
                    </div>
                  </div>
                  <div className="right ml-2">
                    <div className="flex text-sm gap-3 pt-[1px]">
                      {hasRiskStep && (
                        <>
                          <RiskLevelComponent
                            riskLevel={riskLevel}
                            testId={{
                              container: applicantWorkflowEventsQa.riskWrapper,
                            }}
                          />
                          <FrankieButton
                            className="!bg-mono-white text-tertiary-grey-700 outline-1 outline-tertiary-grey-200 truncate"
                            size="sm"
                            onClick={handleRiskScoreClick}
                            testId={{
                              button: applicantWorkflowEventsQa.riskScoreCTA,
                            }}
                          >
                            {t('header.risk.riskScore')}
                          </FrankieButton>
                        </>
                      )}

                      {editIndividualPermission && (
                        <EntityVerificationReverify entityId={entityId} />
                      )}
                      <span>
                        <Menu
                          onSelect={value => {
                            handleSelect(value)
                          }}
                          onFocusClassName={
                            getWorkflowEventResultConfig(t, workflowEventResult)
                              .onFocus
                          }
                          disabled={isArchived || !editIndividualPermission}
                          options={
                            getWorkflowEventResultConfig(t, workflowEventResult)
                              .options
                          }
                          buttonClassName={`min-w-max !h-9 !rounded-sm  justify-between !text-sm focus:!outline-2 ${
                            getWorkflowEventResultConfig(t, workflowEventResult)
                              .styles
                          }`}
                          icons={{
                            open: 'mdiChevronUp',
                            close: 'mdiChevronDown',
                          }}
                          testId={{
                            button: applicantWorkflowEventsQa.statusChangeMenu,
                          }}
                        >
                          {
                            getWorkflowEventResultConfig(t, workflowEventResult)
                              .text
                          }
                        </Menu>
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <EntityVerificationEvents entityId={entityId} />
        </div>
      </CommentHOC>
      <AmlScreeningEvents entityId={entityId} />
    </FrankieLoader>
  )
}
