import { bffClient, IClient } from 'shared/client'

import { MutateIndividualResultsType } from '../model/individual-results.model'

export class IndividualResultsAPI {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  async updateIndividualResult(
    entityId: string,
    type: 'idv' | 'aml',
    manualStatusData: MutateIndividualResultsType,
  ) {
    return this.client.patch(
      `f2/v1/individual/${entityId}/results/${type}`,
      manualStatusData,
    )
  }
}

export const individualResultsAPI = new IndividualResultsAPI(bffClient)
