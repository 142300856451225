import { useMemo } from 'react'

import {
  AddressStatusEnum,
  AddressTypeEnum,
  IDocsToBeDisplayedType,
  useGetWorkflowEventsData,
  ILabelAndSources,
  WorkflowStepResultEnum,
  extractLongForm,
  useEntityDataQuery,
  useEntityLabelDataState,
  WorkflowStatusKeysTypes,
  getWorkflowStatusKey,
  ServiceProfileState,
  ProcessResultManualStatusEnumAML,
  ProcessResultManualStatusEnumKYC,
  ProcessResultSystemStatusEnum,
  WorkflowExecutionSummary,
} from 'entities/entity'
import { useHasFeatureFlag } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes } from 'shared/tracking'

import { workflowEventsEn } from '../locale/workflow-events.en'
import {
  formatDate,
  getSourceMatch,
} from '../model/applicant-workflow-events.model'
import {
  EventName,
  FILTER_STEP_NAMES,
  getIconConfigAml,
  getIDVStepper,
  IconConfig,
  Stepper,
} from '../ui/entity-verification-helper/entity-verification-helper'
import { WORKFLOW_EVENTS_KEY } from '../workflow-events.key'

export type Args = {
  entityId: string
  docR1?: boolean
}

export const useGetWorkFlowDataWithSources = ({
  entityId,
  docR1 = false,
}: Args) => {
  const { data, isLoading } = useGetWorkflowEventsData({ entityId })
  const { data: entity, isFetching } = useEntityDataQuery(entityId, 'base64')
  const {
    documentWithLabelData: documentLabelDataF2R2,
    documentWithLabelDataR1,
  } = useEntityLabelDataState({ entityId })
  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })

  // some places need docs to have R1 data despite being in F2R2
  const documentWithLabelData = docR1
    ? documentWithLabelDataR1
    : documentLabelDataF2R2

  if (!data || !entity) {
    return {
      data: undefined,
      isLoading: isLoading || isFetching,
    }
  }

  const workflowEventResult = data.workflowSummaries?.at(0)

  const isArchived =
    entity.serviceProfiles?.at(0)?.state === ServiceProfileState.ARCHIVED

  const kycStep =
    workflowEventResult?.workflowResultData?.workflowStepResults?.find(
      i => i.stepName === 'KYC',
    )

  const isManuallyVerified =
    kycStep?.result === WorkflowStepResultEnum.MATCH &&
    kycStep.processResults?.every(
      i => i.manualStatus === ProcessResultManualStatusEnumKYC.PASS,
    )

  const isNotUnchecked =
    workflowEventResult?.status &&
    getWorkflowStatusKey(workflowEventResult) !==
      WorkflowStatusKeysTypes.UNCHECKED

  function fullName() {
    const { displayName, givenName, middleName, familyName } =
      entity?.individual?.name ?? {}

    return (
      displayName ??
      `${givenName ?? ''} ${middleName ?? ''} ${familyName ?? ''}`.trim()
    )
  }

  const profileInfo: ILabelAndSources[] = [
    {
      label: t('personalInfo.name'),
      value: fullName(),
      sources: getSourceMatch({
        objectType: 'NAME',
        workflowSummaries: data.workflowSummaries,
      }),
    },
    {
      label: t('personalInfo.dob'),
      value:
        entity.individual?.dateOfBirth?.normalized &&
        formatDate({
          dateString: entity.individual.dateOfBirth.normalized,
        }),
      sources: getSourceMatch({
        objectType: 'DATE_OF_BIRTH',
        workflowSummaries: data.workflowSummaries,
      }),
    },
    {
      label: t('personalInfo.address'),
      value: extractLongForm(
        entity.individual?.addresses?.find(
          address =>
            address.type === AddressTypeEnum.RESIDENTIAL &&
            address.status === AddressStatusEnum.CURRENT,
        ),
      ),
      sources: getSourceMatch({
        objectType: 'ADDRESS',
        workflowSummaries: data.workflowSummaries,
      }),
    },
  ]

  const docsToBeDisplayed: IDocsToBeDisplayedType[] =
    documentWithLabelData?.map(doc => ({
      ...doc,
      label: [
        {
          ...doc.label[0],
          sources: getSourceMatch({
            objectType: 'DOCUMENT',
            idType: doc.type,
            workflowSummaries: data.workflowSummaries,
          }),
        },
        ...doc.label.slice(1),
      ],
    })) || []

  return {
    docsToBeDisplayed,
    isLoading,
    profileInfo,
    isNotUnchecked,
    isManuallyVerified,
    isArchived,
  }
}

export const useOrderWithFailedAndPassed = ({ entityId }: Args) => {
  const { data: workflow } = useGetWorkflowEventsData({ entityId })
  const t = useI18n(WORKFLOW_EVENTS_KEY, { keys: workflowEventsEn })

  const { hasIdvCheck } = useHasFeatureFlag({
    hasIdvCheck: ['idvChecks'],
  })

  const lastWorkflow = useMemo(
    () =>
      workflow?.workflowSummaries?.at(0) ?? ({} as WorkflowExecutionSummary),
    [workflow],
  )
  const order = lastWorkflow.steps?.order
  const failed = lastWorkflow.steps?.failed
  const passed = lastWorkflow.steps?.passed

  const filterStepResults = useMemo(
    () =>
      lastWorkflow.workflowResultData?.workflowStepResults?.filter(
        result => !FILTER_STEP_NAMES.includes(result.stepName as string),
      ),
    [lastWorkflow],
  )

  const filterEvents = useMemo(
    () => filterStepResults?.map(result => result.stepName as EventName),
    [filterStepResults],
  )

  const eventStepper = useMemo(() => {
    if (!filterStepResults) return []

    const stepperList: Stepper[] = []
    filterStepResults.forEach(({ stepName, processResults }) => {
      const eventName = stepName as EventName
      const trackingEventsMap = {
        AML: TrackingEventsTypes.WorkflowEventSummaryAMLClickIndividual,
        KYC: TrackingEventsTypes.WorkflowEventSummaryEntityVerificationClickIndividual,
        IDV: TrackingEventsTypes.WorkflowEventSummaryIDVClickIndividual,
        OCR: TrackingEventsTypes.WorkflowEventSummaryIDVBiometricCheck,
        IDV_DOCUMENT: TrackingEventsTypes.WorkflowEventSummaryIDVDocumentCheck,
        IDV_BIOMETRIC:
          TrackingEventsTypes.WorkflowEventSummaryIDVBiometricCheck,
      }
      const trackingEvent = trackingEventsMap[eventName]

      switch (eventName) {
        case 'AML':
          stepperList.push({
            config: getIconConfigAml(
              lastWorkflow.workflowResultData?.workflowStepResults
                ?.find(step => step.stepName === 'AML')
                ?.processResults?.filter(
                  i =>
                    i.result === 'HIT' &&
                    i.systemStatus === ProcessResultSystemStatusEnum.VALID,
                )
                ?.map(result => result.manualStatus) as
                | (ProcessResultManualStatusEnumAML | undefined)[]
                | undefined,
            ),
            text: t(`entityStatusText.${eventName}`),
            eventName,
            track: trackingEvent,
          })
          return
        case 'IDV': {
          if (!hasIdvCheck) return
          const idvSteppers = getIDVStepper(processResults)

          idvSteppers.forEach(item => {
            stepperList.push({
              config: item.icon,
              text: t(`entityStatusText.${item.name}`),
              eventName: item.name as EventName,
              track: trackingEventsMap[item.name],
            })
          })
          return
        }
        default:
          if (failed?.find(item => item === eventName)) {
            stepperList.push({
              config: IconConfig({ type: WorkflowStepResultEnum.FAIL }),
              text: t(`entityStatusText.${eventName}`),
              eventName,
              track: trackingEvent,
            })
            return
          }
          if (passed?.find(item => item === eventName)) {
            stepperList.push({
              config: IconConfig({ type: WorkflowStepResultEnum.PASS }),
              text: t(`entityStatusText.${eventName}`),
              eventName,
              track: trackingEvent,
            })
            return
          }
          stepperList.push({
            config: IconConfig({ type: WorkflowStepResultEnum.UNCHECKED }),
            text: t(`entityStatusText.${eventName}`),
            eventName,
            track: trackingEvent,
          })
      }
    })
    return stepperList
  }, [
    filterStepResults,
    lastWorkflow.workflowResultData?.workflowStepResults,
    t,
    failed,
    passed,
    hasIdvCheck,
  ])

  return {
    order,
    failed,
    passed,
    filterEvents,
    eventStepper,
  }
}
