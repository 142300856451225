import React from 'react'

import { GalleryItem } from 'shared/image-gallery'
import { PdfPreview } from 'shared/pdf-preview/ui/pdf-preview'
import { TrackingEventsTypes } from 'shared/tracking'

import { ZoomImage } from './zoomed-img'

type MediaProps = {
  allowZoom?: boolean
  gallery: (string | GalleryItem)[]
  imgIdx: number
  isGalleryString: boolean
  trackingEvents?: { zoom?: TrackingEventsTypes }
  rotateDeg: number[]
}

const rotateImg = [
  'rotate-[0deg]',
  'rotate-[90deg]',
  'rotate-[180deg]',
  'rotate-[270deg]',
]

export function Media({
  allowZoom,
  gallery,
  imgIdx,
  isGalleryString,
  trackingEvents,
  rotateDeg,
}: React.PropsWithChildren<MediaProps>) {
  const mediaSrc = isGalleryString
    ? (gallery[imgIdx] as string)
    : (gallery[imgIdx] as GalleryItem).url
  const type = isGalleryString ? 'PHOTO' : (gallery[imgIdx] as GalleryItem).type

  switch (type) {
    case 'PHOTO':
      return (
        <ZoomImage
          allowZoom={allowZoom}
          src={mediaSrc}
          trackingEvent={trackingEvents?.zoom}
          rotateDeg={rotateImg[rotateDeg[imgIdx] ?? 0]}
        />
      )
    case 'VIDEO':
      return (
        <video
          loop
          controls
          key={mediaSrc}
          className="aspect-square mx-auto bg-tertiary-grey-500 rounded-sm"
        >
          <track kind="captions" />
          <source src={mediaSrc} type="video/mp4" />
        </video>
      )
    case 'PDF':
      return (
        <div className="overflow-x-hidden overflow-y-auto max-h-[99%]">
          <PdfPreview
            url={mediaSrc}
            pageProps={{ width: 398, className: 'z-0' }}
          />
        </div>
      )
    default:
      return null
  }
}
