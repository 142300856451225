import {
  mdiCheckCircle,
  mdiCheckCircleOutline,
  mdiAlertCircle,
  mdiAlertCircleOutline,
  mdiAlert,
  mdiArrowCollapseLeft,
  mdiInformation,
  mdiCardAccountDetails,
  mdiCardAccountDetailsOutline,
  mdiChartTimelineVariant,
  mdiChevronUp,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronLeft,
  mdiClose,
  mdiInformationOutline,
  mdiAccountCircleOutline,
  mdiAccountCircle,
  mdiAccount,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiDelete,
  mdiCheck,
  mdiCheckBold,
  mdiEmailOutline,
  mdiFileDocumentOutline,
  mdiCellphoneKey,
  mdiDotsHorizontal,
  mdiSwapVertical,
  mdiArrowUp,
  mdiArrowDown,
  mdiListStatus,
  mdiLockReset,
  mdiMagnify,
  mdiMenu,
  mdiMinus,
  mdiOpenInNew,
  mdiAccountMinusOutline,
  mdiFormatListBulleted,
  mdiSwapHorizontal,
  mdiChartArc,
  mdiCancel,
  mdiPlus,
  mdiUnfoldMoreHorizontal,
  mdiCloseCircle,
  mdiPlusCircle,
  mdiMinusCircle,
  mdiMinusCircleOff,
  mdiHelpCircleOutline,
  mdiBell,
  mdiAccountOutline,
  mdiOfficeBuildingOutline,
  mdiCircleMedium,
  mdiTuneVariant,
  mdiHomeOutline,
  mdiDomain,
  mdiArrowExpand,
  mdiAccountDetailsOutline,
  mdiFilter,
  mdiHome,
  mdiAt,
  mdiCellphone,
  mdiCalendarAccountOutline,
  mdiCloseBoxMultipleOutline,
  mdiCommentTextOutline,
  mdiCommentOutline,
  mdiContentCopy,
  mdiTargetAccount,
  mdiCreditCardScan,
  mdiArrowRight,
  mdiAlertOutline,
  mdiSquareEditOutline,
  mdiArchiveArrowDownOutline,
  mdiArchiveArrowUpOutline,
  mdiSyncCircle,
  mdiSend,
  mdiHelpCircle,
  mdiFileOutline,
  mdiTrashCanOutline,
  mdiTrayArrowDown,
  mdiCircleOutline,
  mdiFileSearchOutline,
  mdiCalendarRange,
  mdiWeb,
  mdiCardBulletedOutline,
  mdiSeal,
  mdiMapLegend,
  mdiCardTextOutline,
  mdiHospitalBoxOutline,
  mdiPassport,
  mdiSetAll,
  mdiIdeogramCjk,
  mdiArrowRightThin,
  mdiCellphoneText,
  mdiLinkVariant,
  mdiRestart,
  mdiDataMatrixScan,
  mdiFaceRecognition,
  mdiAccountMultipleOutline,
  mdiSitemapOutline,
  mdiCloudUploadOutline,
  mdiDotsVertical,
  mdiArrowLeft,
  mdiAccountPlusOutline,
  mdiDownload,
  mdiDeleteOutline,
  mdiArrowExpandRight,
  mdiAccountPlus,
  mdiRotateRight,
  mdiGenderMaleFemale,
  mdiSendOutline,
  mdiAccountCheckOutline,
  mdiOfficeBuildingPlusOutline,
  mdiNewspaperVariantMultipleOutline,
  mdiCheckboxMultipleMarkedOutline,
  mdiCalendarBlank,
  mdiTimerSandComplete,
  mdiReload,
  mdiPlayCircleOutline,
  mdiLoading,
} from '@mdi/js'
import {
  PersonOutline,
  AccountCircleOutlined,
  Check,
} from '@mui/icons-material'

export const MdiIcons = {
  mdiAccount,
  mdiAccountPlusOutline,
  mdiAccountCircle,
  mdiAccountCheckOutline,
  mdiAccountCircleOutline,
  mdiAccountDetailsOutline,
  mdiAccountMinusOutline,
  mdiAccountMultipleOutline,
  mdiAccountOutline,
  mdiAlert,
  mdiAlertCircle,
  mdiAlertCircleOutline,
  mdiAlertOutline,
  mdiArchiveArrowDownOutline,
  mdiArchiveArrowUpOutline,
  mdiArrowCollapseLeft,
  mdiArrowDown,
  mdiArrowExpand,
  mdiArrowExpandRight,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowRightThin,
  mdiArrowUp,
  mdiAt,
  mdiBell,
  mdiCalendarAccountOutline,
  mdiCalendarBlank,
  mdiCalendarRange,
  mdiCancel,
  mdiCardAccountDetails,
  mdiCardAccountDetailsOutline,
  mdiCardBulletedOutline,
  mdiCardTextOutline,
  mdiCellphone,
  mdiCellphoneKey,
  mdiCellphoneText,
  mdiChartArc,
  mdiChartTimelineVariant,
  mdiCheck,
  mdiCheckBold,
  mdiCheckboxMultipleMarkedOutline,
  mdiCheckCircle,
  mdiCheckCircleOutline,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiCircleMedium,
  mdiCircleOutline,
  mdiClose,
  mdiCloseBoxMultipleOutline,
  mdiCloseCircle,
  mdiCloudUploadOutline,
  mdiCommentOutline,
  mdiCommentTextOutline,
  mdiContentCopy,
  mdiCreditCardScan,
  mdiDataMatrixScan,
  mdiDelete,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiDomain,
  mdiEmailOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFaceRecognition,
  mdiFileDocumentOutline,
  mdiFileOutline,
  mdiFileSearchOutline,
  mdiFilter,
  mdiFormatListBulleted,
  mdiGenderMaleFemale,
  mdiHelpCircle,
  mdiHelpCircleOutline,
  mdiHome,
  mdiHomeOutline,
  mdiHospitalBoxOutline,
  mdiIdeogramCjk,
  mdiInformation,
  mdiInformationOutline,
  mdiLinkVariant,
  mdiListStatus,
  mdiLockReset,
  mdiMagnify,
  mdiMapLegend,
  mdiMenu,
  mdiMinus,
  mdiMinusCircle,
  mdiMinusCircleOff,
  mdiNewspaperVariantMultipleOutline,
  mdiOfficeBuildingOutline,
  mdiOfficeBuildingPlusOutline,
  mdiOpenInNew,
  mdiPassport,
  mdiPlus,
  mdiPlusCircle,
  mdiReload,
  mdiRestart,
  mdiSeal,
  mdiSend,
  mdiSendOutline,
  mdiSetAll,
  mdiSitemapOutline,
  mdiSquareEditOutline,
  mdiSwapHorizontal,
  mdiSwapVertical,
  mdiSyncCircle,
  mdiTargetAccount,
  mdiTimerSandComplete,
  mdiTrayArrowDown,
  mdiTrashCanOutline,
  mdiTuneVariant,
  mdiUnfoldMoreHorizontal,
  mdiWeb,
  mdiDownload,
  mdiDeleteOutline,
  mdiAccountPlus,
  mdiRotateRight,
  mdiPlayCircleOutline,
  mdiLoading,
}

export const MuiIcons = {
  PersonOutline,
  AccountCircleOutlined,
  Check,
}

export type FrankieIconMdiEnum = keyof typeof MdiIcons
export type FrankieIconMuiEnum = keyof typeof MuiIcons

export type FrankieIconLibrary = 'mdi' | 'mui'
export type FrankieIconName = FrankieIconMdiEnum | FrankieIconMuiEnum

const FRANKIE_ICON_UNION_SIZES = ['2xs', 'xs', 'sm', 'md', 'lg', 'xl'] as const
type typedFrankieIconUnionSizesList = typeof FRANKIE_ICON_UNION_SIZES
export type FrankieIconUnionSize = typedFrankieIconUnionSizesList[number] // this compiles to 'xs' | 'sm' | ...

export function isFrankieIconUnionSize(
  value: string,
): value is FrankieIconUnionSize {
  return FRANKIE_ICON_UNION_SIZES.includes(value as FrankieIconUnionSize)
}

export type FrankieIconSize =
  | FrankieIconUnionSize
  | `${string}rem`
  | `${string}px`

export interface IFrankieIconProps {
  /**
   * Extra CSS classes
   */
  className?: string

  /**
   * the library used to for the given icon
   */
  library?: FrankieIconLibrary

  /**
   * name of the icon that should be rendered
   */
  name: FrankieIconName

  /**
   * the size at which the icon should be rendered, should be same no matter what library you use
   * could be in a union 'xs', 'sm', 'md', 'lg', 'xl' or a css unit
   * 2xs - 12px
   * xs - 16px
   * sm - 20px
   * md - 24px
   * lg - 32px
   * xl - 64px
   */
  size?: FrankieIconSize

  /**
   * Testing identifiers for Jest and Cypress
   */
  testId?: {
    icon?: string
  }
}

export interface IFrankieIconSvgProps {
  className: string
  library: FrankieIconLibrary
  name: FrankieIconName
}

export interface IFrankieIconSizeProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: ((props: any) => JSX.Element) | null
  library: FrankieIconLibrary
  size: FrankieIconSize
}
