import React from 'react'

import cx from 'classnames'

import { FrankieIcon } from 'frankify/src'

import { formatDate, DateFormatTypes } from 'shared/date-time'
import { useI18n } from 'shared/i18n'

import { INDIVIDUAL_AML_RESULT_KEY } from '../../../individual-aml-result.key'
import { individualAmlResultEn } from '../../../locale/individual-aml-result.en'
import { amlDetailCardQa } from '../../../qa/individual-aml-result.qa'
import { useAmlData } from '../../../state/individual-aml-result.state'

type Args = {
  entityId: string
  processResultId?: string
  historyPage?: boolean
}

export function IndividualAmlEntityAdverseMediaCard({
  entityId,
  processResultId,
  historyPage,
}: Args) {
  const t = useI18n(INDIVIDUAL_AML_RESULT_KEY, {
    keys: individualAmlResultEn,
  })

  const { amlData } = useAmlData({ entityId, processResultId, historyPage })
  const { supplementaryData } = amlData

  const appendTitleDot = (title: string) =>
    title.endsWith('.') ? title : `${title}.`

  return (
    <div data-qa={amlDetailCardQa.container} className="flex flex-col gap-4">
      {supplementaryData?.mediaData?.length ? (
        supplementaryData.mediaData.map(media => (
          <div
            key={media.title}
            className={cx('flex flex-col gap-4 pb-4', {
              'border-b-[1px] border-b-tertiary-grey-200':
                supplementaryData.mediaData?.at(-1) !== media,
            })}
          >
            <div>
              <a
                data-qa={amlDetailCardQa.title}
                className="gap-1 items-center justify-between text-primary-800 font-semibold text-sm leading-5 inline-block"
                href={media.url}
                target="_blank"
                rel="noreferrer"
              >
                {media.source}
              </a>

              <ul className="mt-1" data-qa={amlDetailCardQa.descInfo}>
                <div className="text-tertiary-grey-500 text-xs leading-3">
                  {[
                    media.sourceCountry,
                    media.sourceDate
                      ? `Pubished on ${formatDate(
                          media.sourceDate,
                          DateFormatTypes.FullMonthDate,
                        )}`
                      : null,
                  ]
                    .filter(Boolean)
                    .map((detail, i, arr) => (
                      <span key={detail}>
                        {detail}
                        {i < arr.length - 1 && ' • '}
                      </span>
                    ))}
                </div>
              </ul>
            </div>
            <p
              data-qa={amlDetailCardQa.description}
              className="text-tertiary-grey-700 text-sm leading-5"
            >
              {media.title && (
                <span className="font-bold">
                  {appendTitleDot(media.title)}{' '}
                </span>
              )}

              {media.snippet}
            </p>
          </div>
        ))
      ) : (
        <p className="text-tertiary-grey-700">
          {t('tabMatchCard.noResults.mediaSrcLabel')}
        </p>
      )}
    </div>
  )
}

export function IndividualAmlEntityOtherDataSrcCard({
  entityId,
  processResultId,
  historyPage,
}: Args) {
  const t = useI18n(INDIVIDUAL_AML_RESULT_KEY, {
    keys: individualAmlResultEn,
  })

  const { amlData } = useAmlData({ entityId, processResultId, historyPage })

  const { supplementaryData } = amlData
  return (
    <div
      data-qa={amlDetailCardQa.container}
      className="flex flex-col gap-4 grow-0"
    >
      {supplementaryData?.referenceDocs?.length ? (
        supplementaryData.referenceDocs.map(refDoc => (
          <div className="flex flex-col gap-4 pb-4 border-b-[1px] border-b-tertiary-grey-200">
            <div>
              <div className="text-primary-800 font-semibold text-sm leading-5">
                <a
                  data-qa={amlDetailCardQa.title}
                  className="flex gap-1 items-center justify-between"
                  href={refDoc.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {refDoc.description}
                  <span>
                    <FrankieIcon
                      name="mdiOpenInNew"
                      className="text-tertiary-grey-400"
                      size="xs"
                      testId={{ icon: amlDetailCardQa.icon }}
                    />
                  </span>
                </a>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-tertiary-grey-700">
          {t('tabMatchCard.noResults.otherSrcLabel')}
        </div>
      )}
    </div>
  )
}
