import {
  IProfileFilter,
  IProfileResponse,
  ProfileStateTypes,
} from 'entities/entity'

import { bffClient, IClient } from 'shared/client'
import { Nullable } from 'shared/typescript'

export class ProfilesApi {
  client: IClient

  constructor(client: IClient) {
    this.client = client
  }

  // eslint-disable-next-line complexity, max-params
  async getProfiles(
    sort: { field: 'createdAt' | 'updatedAt'; sort: 'asc' | 'desc' | null },
    limit?: string,
    nextPageMarker?: Nullable<string>,
    filters: IProfileFilter = {} as IProfileFilter,
    signal?: AbortSignal,
  ) {
    const searchFieldAndTerm: { searchTerm?: string; searchField?: string } = {}
    if (filters.entityName) {
      searchFieldAndTerm.searchField = 'NAME'
      searchFieldAndTerm.searchTerm = filters.entityName
    } else if (filters.customerId) {
      searchFieldAndTerm.searchField = 'EXTERNAL_REFERENCE'
      searchFieldAndTerm.searchTerm = filters.customerId
    }

    if (
      filters.states.includes(ProfileStateTypes.INIT) &&
      !filters.states.includes(ProfileStateTypes.AUTO)
    ) {
      filters.states.push(ProfileStateTypes.AUTO)
    }

    return this.client.get<IProfileResponse>('/f2/v1/profiles', {
      params: {
        limit,
        entityId: filters.entityId || undefined,
        states: filters.states.join(',') || undefined,
        issueCategories: filters.issueCategories.join(',') || undefined,
        riskLevels: filters.riskLevels.join(',') || undefined,
        createdAtAfter: filters.createdDate.startDate || undefined,
        createdAtBefore: filters.createdDate.endDate || undefined,
        updatedAtAfter: filters.updatedDate.startDate || undefined,
        updatedAtBefore: filters.updatedDate.endDate || undefined,
        workflowNames: filters.workflowNames.join(',') || undefined,
        workflowExecutionStatuses:
          filters.workflowStatuses.join(',') || undefined,
        assignees: filters.assignees.map(i => i.value).join(',') || undefined,
        sort: sort.sort || 'asc',
        sortField: sort.field,
        searchType: 'FUZZY',
        ...searchFieldAndTerm,
        page: nextPageMarker || undefined,
      },
      signal,
    })
  }
}

export const profilesApi = new ProfilesApi(bffClient)
