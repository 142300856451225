import { useCallback, useMemo } from 'react'

import { DocumentType, DocumentAttachment } from 'entities/entity'
import { IDocsToBeDisplayedType } from 'entities/entity/model/entity-label-data-model'

import { convertDataURItoBlob, makeDataURL } from 'shared/file'

export interface IDocumentAttachmentWithURL extends DocumentAttachment {
  url: string
  isImage: boolean
}

/**
 * Converts a `DocumentAttachment` into an `Attachment`.
 *
 * @param attachment - The document attachment to convert.
 * @returns The converted attachment.
 */
const convertToAttachment = (attachment: DocumentAttachment) => {
  const { createdAt } = attachment
  return {
    ...attachment,
    createdAt: createdAt ?? '1970',
  }
}

/**
 * Groups attachments by their `side` property.
 *
 * @param attachments - Array of attachments to group.
 * @returns An object with attachments grouped by side.
 */
const groupAttachmentsBySide = (attachments: DocumentAttachment[]) =>
  attachments.reduce(
    (acc, attachment) => {
      const { side } = attachment

      if (side) {
        acc[side].push(attachment)
      }

      return acc
    },
    { FRONT: [], BACK: [] } as Record<string, DocumentAttachment[]>,
  )

/**
 * Finds the most recent attachment based on the `createdAt` property.
 *
 * @param attachments - Array of attachments to search.
 * @returns The most recent attachment.
 */
const findMostRecentAttachment = (attachments: DocumentAttachment[]) =>
  attachments.reduce(
    (mostRecent, current) =>
      new Date(current.createdAt || '1970') >
      new Date(mostRecent.createdAt || '1970')
        ? current
        : mostRecent,
    attachments[0] || null,
  )

/**
 * Custom hook to filter and manage document attachments.
 *
 * @param {IDocsToBeDisplayedType[]} [documents] - Optional array of documents to be displayed.
 * @returns {Object} - An object containing:
 *   - `docs`: An array of document objects with filtered attachments.
 *   - `selfies`: An array of selfie attachments.
 *   - `filterMostRecentAttachments`: A function to filter the most recent attachments from the provided documents.
 */

export const useFilteredAttachments = (
  documents?: IDocsToBeDisplayedType[],
) => {
  /**
   * Loads attachments of a specific type.
   * @param type - The type of document to load attachments for.
   * @returns An array of attachments for the specified document type.
   */
  const loadAttachments = useCallback(
    (type: DocumentType) =>
      documents
        ?.find(document => document.type === type)
        ?.attachments?.map(convertToAttachment) ?? [],
    [documents],
  )

  const docs = useMemo(
    () =>
      [
        DocumentType.PASSPORT,
        DocumentType.DRIVERS_LICENSE,
        DocumentType.NATIONAL_HEALTH_ID,
      ].map(type => ({
        type,
        attachments: loadAttachments(type),
      })),
    [loadAttachments],
  )

  const selfies = useMemo(
    () => loadAttachments(DocumentType.SELF_IMAGE),
    [loadAttachments],
  )

  /**
   * Sets the URL and image flag for each attachment. Converting the base64 data to a URL.
   *
   * @param attachments - An array of attachments to set the URL for.
   * @returns An array of attachments with the URL and image flag set.
   */
  const setImageURL = useCallback(
    (attachments: DocumentAttachment[]) =>
      attachments.map(attachment => {
        const { mimeType } = attachment
        const isImage = mimeType ? mimeType.split('/').includes('image') : true
        const url = window.URL.createObjectURL(
          convertDataURItoBlob(
            makeDataURL(attachment.mimeType ?? '', attachment.data.base64),
          ),
        )

        return {
          ...attachment,
          url,
          isImage,
        }
      }),
    [],
  )

  /**
   * Filters the most recent attachments for each document in the provided list.
   *
   * @param docsToFilter - An array of documents to be filtered. Each document should contain a list of attachments.
   * @returns An array of documents with only the most recent attachments for each side (FRONT and BACK).
   *
   * This function groups the attachments of each document by their side (FRONT or BACK),
   * finds the most recent attachment for each side, and returns a new array of documents
   * with these most recent attachments. If no attachments are found for a side, it is excluded.
   */
  const filterMostRecentAttachments = useCallback(
    (docsToFilter: IDocsToBeDisplayedType[] | undefined) =>
      docsToFilter?.map(doc => {
        const groupedAttachments = groupAttachmentsBySide(
          (doc.attachments ?? []).map(
            (attachment: DocumentAttachment) =>
              convertToAttachment(attachment) as unknown as DocumentAttachment,
          ),
        )

        return {
          ...doc,
          attachments: [
            findMostRecentAttachment(groupedAttachments.FRONT),
            findMostRecentAttachment(groupedAttachments.BACK),
          ].filter(Boolean),
        }
      }) ?? [],
    [],
  )

  return { docs, selfies, setImageURL, filterMostRecentAttachments }
}
