import React, { useState } from 'react'

import { FrankieButton, FrankieCheckbox, FrankieRadio } from 'frankify/src'

import { useI18n } from 'shared/i18n'

import {
  FILTERS_COMMON_KEY,
  filtersCommonEn,
} from '../../locale/filters-common.en'
import { filtersQa } from '../../qa/filters.qa'

export interface IOption<T extends string> {
  value: T
  label: string
}

type Props<T extends string> = {
  optionGroups: IOption<T>[][]
  values: T[]
  onChange?: (values: T[]) => void
  lessRowsToShow?: number
  type?: 'radio' | 'checkbox'

  testId?: {
    checkbox?: string
  }
}

export function CheckboxGroup<T extends string>({
  optionGroups,
  values,
  onChange,
  lessRowsToShow,
  testId = { checkbox: '' },
  type = 'checkbox',
}: Props<T>) {
  const t = useI18n([FILTERS_COMMON_KEY], { keys: filtersCommonEn })
  const [isCollapsed, setIsCollapsed] = useState(
    lessRowsToShow !== undefined && values.length === 0,
  )

  const handleToggleCollapse = () => setIsCollapsed(prev => !prev)

  const handleChangeChecked = (option: T, newChecked: boolean) => () => {
    if (onChange) {
      if (type === 'radio') {
        onChange([option])
      } else if (newChecked) {
        onChange([...values, option])
      } else onChange(values.filter(value => value !== option))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const Component = type === 'radio' ? FrankieRadio : FrankieCheckbox
  return (
    <>
      <div className="max-w-full flex flex-initial items-start gap-3">
        {optionGroups.map(col => (
          <div key={col[0]?.value} className="basis-1/3 flex flex-col gap-3">
            {col.map((option, rowIdx) => {
              if (isCollapsed && lessRowsToShow && rowIdx >= lessRowsToShow)
                return null

              const checked = values.includes(option.value)
              return (
                <label
                  className="flex w-max flex-row cursor-pointer"
                  key={option.value}
                >
                  <Component
                    size="sm"
                    checked={checked}
                    onChange={handleChangeChecked(option.value, !checked)}
                    testId={{
                      input: testId.checkbox,
                    }}
                  />
                  <div className="ml-2 leading-tight text-sm">
                    {option.label}
                  </div>
                </label>
              )
            })}
          </div>
        ))}
      </div>
      {lessRowsToShow !== undefined && (
        <FrankieButton
          testId={{ button: filtersQa.cta.viewAllOptions }}
          className="text-sm font-normal text-primary-800 mt-3"
          noStyles
          onClick={handleToggleCollapse}
        >
          {t(isCollapsed ? 'form.cta.viewAll' : 'form.cta.showLess')}
        </FrankieButton>
      )}
    </>
  )
}
