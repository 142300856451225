import React from 'react'

import type { IDocumentAttachmentWithURL } from 'entities/document-f2'
import type { IDocsToBeDisplayedType } from 'entities/entity'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { FrankieUtilityImg } from 'shared/frankie-image'
import { useI18n } from 'shared/i18n'
import { GalleryItem, ImageGallery } from 'shared/image-gallery'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../../../individual-profile-insight.key'
import { individualProfileInsightEn } from '../../../locale/individual-profile-insight.en'
import { useActiveDocIndex } from '../../../state/set-active-doc-index.state'

interface IDocumentsWithURL extends IDocsToBeDisplayedType {
  attachments: IDocumentAttachmentWithURL[]
}

type Props = {
  defaultIndex: number
  docs: IDocumentsWithURL[]
  selfies: IDocumentAttachmentWithURL[]
}

export function IndividualOcrOverlay({ defaultIndex, selfies, docs }: Props) {
  const {
    state,
    setActiveIndex,
    setActiveDocumentIndex,
    setActiveSubDocumentIndex,
  } = useActiveDocIndex(defaultIndex)

  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })

  const getHeaderSlot = () => (
    <p className="flex justify-between text-tertiary-grey-500">
      {t('modal.uploadedOn', {
        date: formatDate(
          docs[state.activeDocumentIndex]?.attachments?.[
            state.activeSubDocumentIndex
          ]?.createdAt || 0,
          DateFormatTypes.DateNumbersSlash,
        ),

        time: formatDate(
          docs[state.activeDocumentIndex]?.attachments?.[
            state.activeSubDocumentIndex
          ]?.createdAt || 0,
          DateFormatTypes.Time24HoursWithSeconds,
        ),
      })}
    </p>
  )

  return (
    <>
      <h1 className="text-xl mb-4 font-bold text-tertiary-grey-800">
        {t('modal.compareImages')}
      </h1>
      <div className="flex gap-6">
        <div className="flex w-[400px] flex-col justify-between shrink gap-3">
          <p className="flex justify-between text-tertiary-grey-500">
            {t('modal.uploadedOn', {
              date: formatDate(
                selfies[state.activeIndex].createdAt as string,
                DateFormatTypes.DateNumbersSlash,
              ),
              time: formatDate(
                selfies[state.activeIndex].createdAt as string,
                DateFormatTypes.Time24HoursWithSeconds,
              ),
            })}
          </p>

          {selfies[state.activeIndex].isImage ? (
            <FrankieUtilityImg
              allowZoom={false}
              gallery={[selfies[state.activeIndex]?.url]}
              trackingEvents={{
                rotate: TrackingEventsTypes.ProfileSelfieCompareRotate,
              }}
            />
          ) : (
            <video
              loop
              autoPlay
              controls
              key={selfies[state.activeIndex].attachmentId}
              className="aspect-square mx-auto  bg-tertiary-grey-500 rounded-sm"
            >
              <track kind="captions" />
              <source type="video/mp4" src={selfies[state.activeIndex]?.url} />
            </video>
          )}
          <ImageGallery
            gallery={selfies.map(
              attachment =>
                ({
                  id: attachment.attachmentId,
                  url: attachment.url,
                  type: attachment.type,
                  side: attachment.side,
                  createdAt: attachment.createdAt,
                  mimeType: attachment.mimeType,
                } as GalleryItem),
            )}
            selected={state.activeIndex}
            onChange={idx => {
              setActiveIndex(idx)
              trackingManager.track(
                TrackingEventsTypes.ProfileSelfieCompareThumbnailClick,
              )
            }}
          />
        </div>
        <div className="flex w-[400px] flex-col shrink gap-3">
          <FrankieUtilityImg
            key={state.activeDocumentIndex}
            gallery={docs[state.activeDocumentIndex].attachments.map(
              attachment =>
                ({
                  id: attachment.attachmentId,
                  side: attachment.side,
                  url: attachment.url,
                  type: attachment.type,
                  createdAt: attachment.createdAt,
                  mimeType: attachment.mimeType,
                } as GalleryItem),
            )}
            trackingEvents={{
              rotate: TrackingEventsTypes.ProfileSelfieCompareDocRotate,
              zoom: TrackingEventsTypes.ProfileSelfieCompareDocZoom,
              gallery: [
                TrackingEventsTypes.ProfileSelfieCompareDocFront,
                TrackingEventsTypes.ProfileSelfieCompareDocBack,
              ],
            }}
            headerSlot={getHeaderSlot}
            onImageChange={setActiveSubDocumentIndex}
          />
          <ImageGallery
            gallery={
              docs
                .map(doc => {
                  const attachment = doc.attachments[0] || {}
                  return {
                    id: attachment.url,
                    url: attachment.url,
                    type: attachment.type,
                    createdAt: attachment.createdAt,
                    mimeType: attachment.mimeType,
                    side: attachment.side,
                  }
                })
                .filter(Boolean) as GalleryItem[]
            }
            selected={state.activeDocumentIndex}
            onChange={idx => {
              setActiveSubDocumentIndex(0)
              setActiveDocumentIndex(idx)
              trackingManager.track(
                TrackingEventsTypes.ProfileSelfieCompareDocSwitch,
              )
            }}
          />
        </div>
      </div>
    </>
  )
}
