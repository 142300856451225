import React, { useMemo } from 'react'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FrankieButton } from 'frankify/src'

import { IProfileFilter, WorkflowStatusKeysTypes } from 'entities/entity'

import { CheckboxGroup, IOption } from 'shared/filters-common'
import { useI18n } from 'shared/i18n'

import {
  PROFILES_FILTER_KEY,
  profilesFilterEn,
} from '../../locale/profiles-filter.en'
import { workflowStatesI18n } from '../../model/profiles-filter-form.model'
import { profileGlobalFilterDefault } from '../../model/profiles-filter.model'
import { applicantsFilterQa } from '../../qa/applicants-filter.qa'

type IWorkflowStatesFilter = Pick<IProfileFilter, 'workflowStatuses'>

const defaultValues: IWorkflowStatesFilter = profileGlobalFilterDefault
type Props = {
  statusesOptions: WorkflowStatusKeysTypes[]
  onClose: () => void
  initialValues?: IWorkflowStatesFilter
  onSubmit: (filters: IWorkflowStatesFilter) => void
}

export function WorkflowStatusesForm({
  statusesOptions,
  onClose,
  initialValues,
  onSubmit,
}: Props) {
  const t = useI18n([PROFILES_FILTER_KEY], { keys: profilesFilterEn })

  const statusOptions = useMemo<IOption<WorkflowStatusKeysTypes>[][]>(
    () => [
      statusesOptions.map(option => ({
        value: option,
        label: t(workflowStatesI18n[option]),
      })),
    ],
    [t, statusesOptions],
  )

  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<IWorkflowStatesFilter>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    values: initialValues || defaultValues,
  })

  const handleClear = () => {
    onSubmit({
      ...initialValues,
      workflowStatuses: defaultValues.workflowStatuses,
    })
    onClose()
  }

  const handleFormSubmit: SubmitHandler<IWorkflowStatesFilter> = data => {
    onSubmit(data)
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full p-4">
      <Controller
        control={control}
        name="workflowStatuses"
        render={({ field: { onChange, value } }) => (
          <CheckboxGroup
            type="checkbox"
            onChange={onChange}
            optionGroups={statusOptions}
            values={value}
            testId={{ checkbox: applicantsFilterQa.inputs.statusOption }}
          />
        )}
      />
      <div className="flex flex-row items-center gap-3 mt-4">
        <FrankieButton
          className="basis-1/2"
          testId={{ button: applicantsFilterQa.cta.applySingleFilter }}
          size="xs"
          disabled={!isDirty}
          type="submit"
        >
          {t('form.cta.apply')}
        </FrankieButton>
        <FrankieButton
          className="basis-1/2"
          testId={{ button: applicantsFilterQa.cta.clearSingleFilter }}
          size="xs"
          intent="darkOutline"
          onClick={handleClear}
        >
          {t('form.cta.clear')}
        </FrankieButton>
      </div>
    </form>
  )
}
