import React from 'react'

import { useInView } from 'react-intersection-observer'

import { FrankieDivider, FrankieIcon } from 'frankify/src'

import { IndividualProfileDocumentQa } from 'features/individual-profile-insight/qa/individual-profile-insight.qa'

import {
  DOCUMENT_F2_KEY,
  DocumentViewF2R2,
  documentF2En,
  documentIdTypesOption,
  useFilteredAttachments,
} from 'entities/document-f2'
import { DocumentType, useEntityLabelDataState } from 'entities/entity'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from '../../individual-profile-insight.key'
import { individualProfileInsightEn } from '../../locale/individual-profile-insight.en'

type Props = {
  entityId: string
  isInvalidated?: boolean
}

export function IndividualProfileDocumentR2({
  entityId,
  isInvalidated,
}: Props) {
  const { ref, inView } = useInView()
  const tDocument = useI18n(DOCUMENT_F2_KEY, { keys: documentF2En })
  const { documentWithLabelData } = useEntityLabelDataState({ entityId })
  const { filterMostRecentAttachments } = useFilteredAttachments(
    documentWithLabelData,
  )

  const t = useI18n(INDIVIDUAL_PROFILE_INSIGHT_KEY, {
    keys: individualProfileInsightEn,
  })

  useTrackingShowEvents({
    data: inView,
    eventsType: TrackingEventsTypes.ProfileDocumentShow,
  })

  return (
    <div
      className="flex flex-col gap-y-3"
      data-qa={IndividualProfileDocumentQa.container}
      ref={ref}
    >
      <h1 className="font-bold text-tertiary-grey-800 text-xl flex items-center gap-1">
        {isInvalidated && (
          <FrankieIcon
            size="md"
            name="mdiAlertCircle"
            className="text-tertiary-yellow-300"
            testId={{ icon: IndividualProfileDocumentQa.tabDocumentIcon }}
          />
        )}
        {t('tab.documents')}
      </h1>

      <div className="flex flex-col gap-y-2">
        {filterMostRecentAttachments(documentWithLabelData)
          .filter(document =>
            [
              DocumentType.PASSPORT,
              DocumentType.DRIVERS_LICENSE,
              DocumentType.NATIONAL_HEALTH_ID,
            ].includes(document.type),
          )
          .map((document, idx) => {
            const hasAttachment = document.attachments.length > 0

            const { tKey = '' } =
              documentIdTypesOption.find(
                ({ value }) => value === document.type,
              ) ?? {}

            return (
              <React.Fragment key={document.documentId}>
                {tKey ? (
                  <h2 className="font-semibold text-md leading-6 text-tertiary-grey-800">
                    {tDocument(tKey)}
                  </h2>
                ) : null}

                <div className="w-full flex grow-0 shrink-0 gap-6">
                  {hasAttachment ? (
                    <div
                      className="w-[220px] desktop:w-[250px]"
                      data-qa={
                        IndividualProfileDocumentQa.documentViewContainer
                      }
                    >
                      <DocumentViewF2R2
                        document={document}
                        trackingEvents={{
                          gallery: [
                            TrackingEventsTypes.ProfileDocumentFront,
                            TrackingEventsTypes.ProfileDocumentBack,
                          ],
                          rotate:
                            TrackingEventsTypes.ProfileDocumentExpandRotate,
                          zoom: TrackingEventsTypes.ProfileDocumentExpandZoom,
                          show: TrackingEventsTypes.ProfileDocumentExpandShow,
                        }}
                        className="aspect-video"
                        extraData={document.extraData}
                      />
                    </div>
                  ) : null}

                  <div
                    className="grid grid-cols-3 gap-x-6 gap-y-3 auto-rows-max"
                    data-qa={IndividualProfileDocumentQa.srcLabelContainer}
                  >
                    {[...document.label].map(({ label, value }) =>
                      !value ? null : (
                        <div key={label} className="basis-1/3 min-w-[193px]">
                          <p className="font-medium text-tertiary-grey-800">
                            {label}
                          </p>
                          <p
                            data-hj-suppress
                            className="font-normal text-tertiary-grey-700"
                          >
                            {value}
                          </p>
                        </div>
                      ),
                    )}
                    <p className="w-full col-span-full text-tertiary-grey-500 text-sm leading-5 mt-4">
                      {t('uploadedAt', {
                        date: formatDate(
                          document.attachments[0].createdAt || 0,
                          DateFormatTypes.DateNumbersSlash,
                        ),
                        time: formatDate(
                          document.attachments[0].createdAt || 0,
                          DateFormatTypes.Time24HoursWithSeconds,
                        ),
                      })}
                    </p>
                  </div>
                </div>
                {idx < (documentWithLabelData || []).length - 1 ? (
                  <div className="py-4 mt-1">
                    <FrankieDivider className="w-full bg-tertiary-grey-200" />
                  </div>
                ) : null}
              </React.Fragment>
            )
          })}
      </div>
    </div>
  )
}
