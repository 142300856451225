import { capitalCase, sentenceCase } from 'change-case'
import { unflatten } from 'flat'
import get from 'lodash.get'

import {
  DocumentAttachment,
  DocumentInformation,
  IndividualsEntityResponse,
  ProcessResultObject,
  ServiceProfile,
} from 'entities/entity/model/entity.model'

import { DateFormatTypes, formatDate } from 'shared/date-time'
import { GalleryImage } from 'shared/document-thumbs/ui/document-thumbs'
import { getDataFileUrl } from 'shared/file'
import { Nullable } from 'shared/typescript'

type IdvValues = {
  label: string
  value: string
  success?: boolean
}

type IdvCheckReport = {
  key: string
  title: string
  success: boolean
  isStatusUnknown: boolean
  description: string
  children?: IdvCheckReport[]
  value?: string
}

type Score = Nullable<{
  success: boolean
  score: number
}>

export type IdvDocument = {
  title: string
  values: IdvValues[]
  comparisonValues: OcrComparison[]
  report: IdvCheckReport[]
  attachments?: GalleryImage[]
  score: {
    idvScore: Score
    ocrScore: Score
  }
  uploadedOn: {
    date: string
    time: string
    via: string | null
  }
  updatedOn: {
    date: string
    time: string
  }
}

const IdvValuesMap: Record<string, [string, string, string][]> = {
  DRIVERS_LICENSE: [
    [
      'Full Name',
      'entity.individual.name.displayName',
      'ocr.supplementaryData.mismatchMap.OcrScannedFullName',
    ],
    [
      'Date of Birth',
      'entity.individual.dateOfBirth.normalized', // TODO: @rozak handle date formatting
      'ocr.supplementaryData.mismatchMap.OcrScannedDateOfBirth',
    ],
    [
      'State/Territory',
      'doc.subdivision',
      'ocr.supplementaryData.mismatchMap.OcrScannedIssuingState',
    ],
    [
      'Licence Number',
      'doc.primaryIdentifier',
      'ocr.supplementaryData.mismatchMap.OcrScannedDocumentNumber',
    ],
    [
      'Card Number',
      'doc.secondaryIdentifier',
      'ocr.supplementaryData.mismatchMap.OcrScannedReferenceNumber',
    ],
  ],
  PASSPORT: [
    [
      'Full Name',
      'entity.individual.name.displayName',
      'ocr.supplementaryData.mismatchMap.OcrScannedFullName',
    ],
    [
      'Date of Birth',
      'entity.individual.dateOfBirth.normalized', // TODO: @rozak handle date formatting
      'ocr.supplementaryData.mismatchMap.OcrScannedDateOfBirth',
    ],
    [
      'Passport Number',
      'doc.primaryIdentifier',
      'ocr.supplementaryData.mismatchMap.OcrScannedDocumentNumber',
    ],
  ],
  NATIONAL_HEALTH_ID: [
    [
      'Display Name',
      'entity.individual.name.displayName',
      'ocr.supplementaryData.mismatchMap.OcrScannedFullName',
    ],
    [
      'Card Number',
      'doc.primaryIdentifier',
      'ocr.supplementaryData.mismatchMap.OcrScannedDocumentNumber',
    ],
  ],
}

type OcrComparedValues = {
  label: string
  ocrKey: string | string[]
  entityPath: string | ((payload: OcrComparisonPayload) => string)
}

const COMMON_OCR_COMPARISON_MAP: OcrComparedValues[] = [
  {
    label: 'Given Name',
    ocrKey: ['OcrScannedGivenName', 'OcrScannedFirstName'],
    entityPath: 'entity.individual.name.givenName',
  },
  {
    label: 'Middle Name',
    ocrKey: 'OcrScannedMiddleName',
    entityPath: 'entity.individual.name.middleName',
  },
  {
    label: 'Last Name',
    ocrKey: 'OcrScannedLastName',
    entityPath: 'entity.individual.name.familyName',
  },
  {
    label: 'Date of Birth',
    ocrKey: 'OcrScannedDateOfBirth',
    entityPath: 'entity.individual.dateOfBirth.normalized',
  },
  {
    label: 'Country of Issue',
    ocrKey: 'OcrScannedIssuingCountry',
    entityPath: 'doc.country',
  },
  {
    label: 'Date of Expiry',
    ocrKey: ['OcrScannedExpiryDate', 'OcrScannedDateOfExpiry'],
    entityPath: 'doc.expiryDate.normalized',
  },
  {
    label: 'Date of Issue',
    ocrKey: ['OcrScannedIssueDate', 'OcrScannedDateOfIssue'],
    entityPath: 'doc.issueDate.normalized',
  },
  {
    label: 'Residential Address',
    ocrKey: 'OcrScannedAddress',
    entityPath: 'entity.individual.addresses.0.unstructuredLongForm',
  },
  {
    label: 'Gender',
    ocrKey: 'OcrScannedGender',
    entityPath: payload => {
      const gender = payload.entity.individual?.gender?.gender
      if (!gender || gender === 'UNSPECIFIED') return '-'
      return gender
    },
  },
]

type DocumentType = 'DRIVERS_LICENSE' | 'PASSPORT' | 'NATIONAL_HEALTH_ID'
const OCR_COMPARISON_MAP: Record<DocumentType, OcrComparedValues[]> = {
  DRIVERS_LICENSE: [
    ...COMMON_OCR_COMPARISON_MAP,
    {
      label: 'Licence Number',
      ocrKey: 'OcrScannedDocumentNumber',

      entityPath: 'doc.primaryIdentifier',
    },
    {
      label: 'Card Number',
      ocrKey: 'OcrScannedIdNumber',
      entityPath: 'doc.secondaryIdentifier',
    },
    {
      label: 'Issuing State',
      ocrKey: 'OcrScannedIssuingState',
      entityPath: 'doc.subdivision',
    },
  ],
  PASSPORT: [
    ...COMMON_OCR_COMPARISON_MAP,
    {
      label: 'Passport Number',
      ocrKey: 'OcrScannedDocumentNumber',
      entityPath: 'doc.primaryIdentifier',
    },
  ],
  NATIONAL_HEALTH_ID: [
    ...COMMON_OCR_COMPARISON_MAP,
    {
      label: 'Card Number',
      ocrKey: 'OcrScannedDocumentNumber',
      entityPath: 'doc.primaryIdentifier',
    },
  ],
}
type OcrComparison = {
  label: string
  ocrValue: string
  entityValue: string
  match: boolean
}
type OcrComparisonPayload = {
  entity: IndividualsEntityResponse
  doc: DocumentInformation
  ocr: ProcessResultObject<'IDV_OCR_COMPARISON'>
}
function makeOcrComparisonValues(
  doc?: DocumentInformation,
  ocrReport?: ProcessResultObject<'IDV_OCR_COMPARISON'>,
  entity?: IndividualsEntityResponse,
): OcrComparison[] {
  if (!doc || !ocrReport || !entity) return []
  const payload: OcrComparisonPayload = { entity, doc, ocr: ocrReport }

  const remainingOcrResult = {
    ...(ocrReport.supplementaryData?.resultMap || {}),
  }

  // Always remove the OcrScannedMismatch object
  delete remainingOcrResult.OcrScannedMismatch

  const docType = doc.type as DocumentType
  const comparison = OCR_COMPARISON_MAP[docType].map(
    ({ label, entityPath, ocrKey }) => {
      let ocrValue = ''
      let match = false
      if (Array.isArray(ocrKey)) {
        for (const key of ocrKey) {
          if (!ocrValue) {
            ocrValue = get(
              payload,
              `ocr.supplementaryData.resultMap.${key}.resultNormalized`,
              '',
            )
            match = !get(
              payload,
              `ocr.supplementaryData.mismatchMap.${key}.originalName`,
              '',
            )
          }
          delete remainingOcrResult[key]
        }
      } else {
        ocrValue = get(
          payload,
          `ocr.supplementaryData.resultMap.${ocrKey}.resultNormalized`,
          '',
        )
        match = !get(
          payload,
          `ocr.supplementaryData.mismatchMap.${ocrKey}.originalName`,
          '',
        )
        delete remainingOcrResult[ocrKey]
      }

      const entityValue =
        typeof entityPath === 'function'
          ? entityPath(payload)
          : get(payload, entityPath, '')

      return {
        label,
        ocrValue,
        entityValue,
        match,
      } as OcrComparison
    },
  )

  // Always remove OcrScannedFullName from remainingOcrResult
  delete remainingOcrResult.OcrScannedFullName

  // Add remaining OCR values
  Object.keys(remainingOcrResult).forEach(key => {
    comparison.push({
      label: capitalCase(key.replace('OcrScanned', '')),
      ocrValue: remainingOcrResult[key].resultNormalized,
      entityValue: '-',
      match: true,
    })
  })

  return comparison
}

function getScore(processResult?: ProcessResultObject<'IDV_DOCUMENT'>): {
  idvScore: Score
  ocrScore: Score
} {
  if (!processResult) return { idvScore: null, ocrScore: null }

  const resultMap = (processResult.supplementaryData?.resultMap ||
    {}) as Record<string, Record<string, string> | undefined>

  const idValidation = resultMap['Report.Scores.IdValidation.Score']
  const ocrConfidence = resultMap['Report.Scores.OCRConfidence.Score']

  return {
    idvScore: idValidation
      ? {
          success:
            idValidation.resultNormalized.toLocaleLowerCase() === 'clear',
          score: Number(idValidation.originalData || 0),
        }
      : null,
    ocrScore: ocrConfidence
      ? {
          success:
            ocrConfidence.resultNormalized.toLocaleLowerCase() === 'clear',
          score: Number(ocrConfidence.originalData || 0),
        }
      : null,
  }
}

function makeIdvValues(
  doc?: DocumentInformation,
  ocrResult?: ProcessResultObject<'IDV_OCR_COMPARISON'>,
  entity?: IndividualsEntityResponse,
): IdvValues[] {
  if (!doc || !ocrResult || !entity) return []
  const payload = { entity, doc, ocr: ocrResult }
  return IdvValuesMap[doc.type].map(
    ([label, path, mismatchPath]: [string, string, string]) => ({
      label,
      value: get(payload, path, '-'),
      success: !get(payload, mismatchPath, null),
    }),
  )
}

type Check = {
  originalName: string
  originalData: string
  resultNormalized: string
}

type TreeNode = {
  id: string
  name: string
  data: Check
  children?: Record<string, TreeNode>
}

const FILTER_KEYS = ['Status']
function buildTree(flatJson: Record<string, string>): TreeNode[] {
  const result = {} as Record<string, string>
  const orderKeys = new Set<string>()
  for (const key in flatJson) {
    if (Object.prototype.hasOwnProperty.call(flatJson, key)) {
      const value = flatJson[key]

      const [, ...parts] = key.split('.')
      // Exclude .Status keys
      if (!FILTER_KEYS.includes(parts[0])) {
        let newKey = ''
        if (parts.length === 1) {
          ;[newKey] = parts
        } else {
          newKey = `${parts.join('.children.')}`
        }

        const id = parts[parts.length - 1]
        result[`${newKey}.id`] = id
        result[`${newKey}.name`] = sentenceCase(id)
        result[`${newKey}.data`] = value

        if (parts[0] !== 'Overall') {
          orderKeys.add(parts[0])
        }
      }
    }
  }
  orderKeys.add('Overall') // Always add Overall to the end

  const map: Record<string, TreeNode> = unflatten(result)
  const output = [] as TreeNode[]

  // Update parent status based on children status
  Object.entries(map).forEach(([key, node]) => {
    const childrenStatus = Object.values(node.children || {}).map(
      child => child.data.resultNormalized,
    )

    if (childrenStatus.length && node.name !== 'Scores') {
      const status = childrenStatus.every(s => s === 'clear')
        ? 'clear'
        : 'rejected'
      map[key].data.resultNormalized = status
    }
  })

  orderKeys.forEach(key => {
    output.push(map[key])
  })

  return output
}

function isClear(node: TreeNode): boolean {
  let str = ''
  if (node.data.originalName.includes('Scores') && node.children?.Score) {
    str = node.children.Score.data.resultNormalized || ''
  } else {
    str = node.data.resultNormalized
  }
  const lower = str.toLocaleLowerCase()

  switch (lower) {
    case 'clear':
      return true
    case 'suspected':
    case 'rejected':
    case '':
      return false
    default: // TODO: @rozakbuhari check other values e.g: "100.0" in Score.
      return false
  }
}

function getValueIfScore(node: TreeNode): string | null {
  if (!node.data.originalName.includes('Scores')) {
    return null
  }
  const scoreValue = node.children?.Score.data.originalData || '0.0'
  return `${scoreValue}%`
}

function isStatusUnknown(node: TreeNode): boolean {
  return !['clear', 'rejected', 'suspected'].includes(
    node.data.resultNormalized.toLowerCase(),
  )
}

function makeIdvReports(
  processResult?: ProcessResultObject<'IDV_DOCUMENT'>,
): IdvCheckReport[] {
  const resultMap = processResult?.supplementaryData?.resultMap
  if (!resultMap) return []

  const reportTree = buildTree(resultMap)

  const result = reportTree.map(node => {
    const clear = isClear(node)
    return {
      key: node.id,
      title: node.name,
      success: clear,
      isStatusUnknown: isStatusUnknown(node),
      description: clear ? 'Pass' : 'Fail',
      children: Object.values(node.children || {}).map(child => {
        const clear = isClear(child)
        return {
          key: child.id,
          title: child.name,
          success: clear,
          isStatusUnknown: isStatusUnknown(child),
          description: clear ? 'Pass' : 'Fail',
          value: getValueIfScore(child),
        }
      }),
    } as IdvCheckReport
  })

  return result
}

function getDocumentId(processResult: ProcessResultObject<'IDV_OCR'>) {
  let documentId = processResult.supplementaryData?.scannedDocumentId
  if (processResult.objectType === 'DOCUMENT') {
    documentId = processResult.objectId
  }
  return documentId
}

function getAttachments(
  processResult: ProcessResultObject<'IDV_OCR'>,
  document: DocumentInformation,
): GalleryImage[] {
  const attachmentIds = [
    processResult.supplementaryData?.scannedAttachmentFrontId,
    processResult.supplementaryData?.scannedAttachmentBackId,
  ]
  const files = attachmentIds
    .map(id => document.attachments?.find(a => a.attachmentId === id))
    .filter(Boolean) as DocumentAttachment[]
  return files.map(attachment => {
    if (!(attachment.data.base64 && attachment.mimeType))
      return {} as GalleryImage

    return {
      url: getDataFileUrl(attachment.mimeType, attachment.data.base64),
      side: attachment.side,
      mimeType: attachment.mimeType,
      id: attachment.attachmentId,
      type: attachment.type,
      createdAt: attachment.createdAt,
    } as GalleryImage
  })
}

type ProcessResultType =
  | 'IDV_DOCUMENT'
  | 'IDV_FACIAL_COMPARISON'
  | 'IDV_FACIAL_LIVENESS'
  | 'IDV_OCR'
  | 'IDV_OCR_COMPARISON'

type ProcessResultMap<T extends ProcessResultType> = Record<
  T,
  ProcessResultObject<T>
>

export function makeIdvDocuments(
  entity?: IndividualsEntityResponse,
  workflow?: ServiceProfile,
): IdvDocument[] {
  if (!entity || !workflow) return [] as IdvDocument[]

  const latestWorkflowRun = workflow.workflowSummaries?.at(0)

  const idvStep =
    latestWorkflowRun?.workflowResultData?.workflowStepResults?.find(
      i => i.stepName === 'IDV',
    )

  const processResultMap = idvStep?.processResults?.reduce<
    ProcessResultMap<ProcessResultType>
  >(
    (
      acc: ProcessResultMap<ProcessResultType>,
      item: ProcessResultObject<ProcessResultType>,
    ) => {
      acc[item.supplementaryData?.type as ProcessResultType] = item
      return acc
    },
    {} as ProcessResultMap<ProcessResultType>,
  )
  if (!processResultMap) return [] as IdvDocument[]

  const idvDocumentResult =
    processResultMap.IDV_DOCUMENT as ProcessResultObject<'IDV_DOCUMENT'>
  const idvOcrResult =
    processResultMap.IDV_OCR as ProcessResultObject<'IDV_OCR'>
  const idvOcrCompResult =
    processResultMap.IDV_OCR_COMPARISON as ProcessResultObject<'IDV_OCR_COMPARISON'>

  const documentId = getDocumentId(idvOcrResult)
  const document = entity.individual?.documents?.IDENTITY?.find(
    doc => doc.documentId === documentId,
  )
  const attachments = document ? getAttachments(idvOcrResult, document) : []

  const comparisonValues = makeOcrComparisonValues(
    document,
    idvOcrCompResult,
    entity,
  )

  const idvDocument: IdvDocument = {
    attachments,
    comparisonValues,
    title: idvDocumentResult.supplementaryData?.detectedDocumentType || '',
    values: makeIdvValues(document, idvOcrCompResult, entity),
    report: makeIdvReports(idvDocumentResult),
    score: getScore(idvDocumentResult),
    uploadedOn: {
      date: formatDate(
        document?.createdAt || '',
        DateFormatTypes.DateNumbersSlash,
      ),
      time: formatDate(
        document?.createdAt || '',
        DateFormatTypes.Time24HoursWithSeconds,
      ),
      via: null,
    },
    updatedOn: {
      date: formatDate(
        idvOcrCompResult.updatedAt || '',
        DateFormatTypes.DateNumbersSlash,
      ),
      time: formatDate(
        idvOcrCompResult.updatedAt || '',
        DateFormatTypes.Time24HoursWithSeconds,
      ),
    },
  }

  return [idvDocument]
}
