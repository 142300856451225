import React, { ReactNode, useMemo } from 'react'

import cx from 'classnames'
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import { FrankieTextField } from 'frankify/src'

import { formatAddress } from 'entities/applicant/model/applicant-address.model'

import { SelectFormField } from 'shared/form'
import { useI18n } from 'shared/i18n'

import {
  holdingCountValidation,
  nameValidation,
  totalUnitsValidation,
} from './validation'
import { APPLICANT_SUPPORTING_DOCUMENTS_KEY } from '../../../applicant-supporting-documents.key'
import { applicantSupportingDocumentsEn } from '../../../locale/applicant-supporting-documents.en'
import {
  applicantEntityOption,
  ApplicantTrustTypes,
} from '../../../model/applicant-supporting-documents.model'
import {
  CommonTrustFormTypes,
  getFullName,
  ITrustDeedForm,
  TrustDetailFormTypes,
  TrustFormLabelProps,
  TrustFormTypes,
} from '../../../model/applicant-supporting-trust-deed.model'
import { trustAnalyserEditFormQa } from '../../../qa/applicant-support-documents.qa'
import {
  FormHeader,
  FormFooter,
} from '../form-header-footer/form-header-footer'
import { TrustAnalyserAddressFormDetail } from '../trust-analyser-address-form-detail/trust-analyser-address-form-detail'
import { TrustAnalyserNameFormDetail } from '../trust-analyser-name-form-detail/trust-analyser-name-form-detail'
import { TrustGeneralBeneficiaryForm } from '../trust-general-beneficiary-form/trust-general-beneficiary-form'

export type TrustCommonFormProps = {
  label?: TrustFormLabelProps
  title: string
  formKey: CommonTrustFormTypes
  isLastItem: boolean
  index: number
  register: UseFormRegister<ITrustDeedForm>
  control: Control<ITrustDeedForm, unknown>
  watch: UseFormWatch<ITrustDeedForm>
  onAdd: () => void
  onRemove: () => void
  tag?: ReactNode
  getValues: UseFormGetValues<ITrustDeedForm>
  setValue: UseFormSetValue<ITrustDeedForm>
  errors: FieldErrors<ITrustDeedForm>
  shouldValidate: boolean
}

type RenderSecondRowIndividualInputProps = {
  label?: TrustFormLabelProps
  placeholder?: string
  formKey: CommonTrustFormTypes
  index: number
  register: UseFormRegister<ITrustDeedForm>
  onClickAddressField: () => void
}

function RenderSecondRowIndividualInput(
  props: RenderSecondRowIndividualInputProps,
) {
  const { formKey, index, label, register, placeholder, onClickAddressField } =
    props

  if (formKey === TrustFormTypes.GeneralBeneficiary) return null

  if (formKey === TrustFormTypes.Member) {
    return (
      <FrankieTextField
        {...register(`member.${index}.role`)}
        label={label?.role}
        className="col-span-2"
        testId={{
          input: trustAnalyserEditFormQa.role(formKey, index + 1),
        }}
        data-hj-suppress
      />
    )
  }

  return (
    <FrankieTextField
      {...register(`${formKey}.${index}.address`)}
      label={label?.addressIndividual}
      className="col-span-2"
      placeholder={placeholder}
      testId={{
        input: trustAnalyserEditFormQa.address(formKey, index + 1),
      }}
      onClick={onClickAddressField}
      readOnly
      data-hj-suppress
    />
  )
}

type RenderFormContentProps = {
  trustDetailFormType: TrustDetailFormTypes
  setTrustDetailFormType: (type: TrustDetailFormTypes) => void
  handleFocus: (event: React.FocusEvent<HTMLInputElement, Element>) => void
}

function RenderFormContent({
  trustDetailFormType,
  setTrustDetailFormType,
  label,
  formKey,
  index,
  register,
  control,
  watch,
  errors,
  getValues,
  handleFocus,
  shouldValidate,
}: TrustCommonFormProps & RenderFormContentProps) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const applicantEntityOptions = useMemo(
    () =>
      applicantEntityOption.map(({ tKey, value }) => ({
        label: t(tKey),
        value,
      })),
    [t],
  )

  if (formKey === TrustFormTypes.GeneralBeneficiary) return null

  const type = watch(`${formKey}.${index}.type`)

  if (
    trustDetailFormType === TrustDetailFormTypes.NAME &&
    type === ApplicantTrustTypes.Individual
  ) {
    return (
      <TrustAnalyserNameFormDetail
        register={register}
        formKey={formKey}
        index={index}
      />
    )
  }

  if (trustDetailFormType === TrustDetailFormTypes.ADDRESS) {
    return (
      <TrustAnalyserAddressFormDetail
        watch={watch}
        control={control}
        register={register}
        formKey={formKey}
        index={index}
      />
    )
  }

  const onClickNameField = () => {
    if (type === ApplicantTrustTypes.Individual) {
      setTrustDetailFormType(TrustDetailFormTypes.NAME)
    }
  }

  const onClickAddressField = () => {
    setTrustDetailFormType(TrustDetailFormTypes.ADDRESS)
  }

  const getNameValue = (idx: number) => {
    const values = getValues()

    const {
      givenName = '',
      middleName = '',
      familyName = '',
    } = values[formKey][idx]?.detail?.name || {}

    const fullName = getFullName({ givenName, middleName, familyName })
    return fullName
  }

  const beneficiaryHasValue = !!watch('generalBeneficiary').find(x => x.value)
  const hasNameError = errors[formKey]?.[index]?.name
  const hasNumberError = errors[formKey]?.[index]?.holdingCount

  return (
    <div className="grid grid-cols-3 gap-4">
      <SelectFormField
        label={label?.type}
        placeholder={t('selectType')}
        control={control}
        name={`${formKey}.${index}.type`}
        options={applicantEntityOptions}
        rules={{ required: t('errorTypeUnknown') }}
        showErrorText
        testId={{ input: trustAnalyserEditFormQa.type(formKey, index + 1) }}
      />
      {type === ApplicantTrustTypes.Organization ? (
        <FrankieTextField
          {...register(
            `${formKey}.${index}.name`,
            nameValidation(
              shouldValidate,
              { formKey, beneficiaryHasValue },
              {
                common: t('inputValidation.name.common'),
                beneficiary: t('inputValidation.name.beneficiary'),
              },
            ),
          )}
          error={!!hasNameError}
          errorText={hasNameError?.message}
          label={label?.name}
          className="col-span-2"
          testId={{ input: trustAnalyserEditFormQa.name(formKey, index + 1) }}
          data-hj-suppress
        />
      ) : (
        <FrankieTextField
          {...register(
            `${formKey}.${index}.name`,
            nameValidation(
              shouldValidate,
              { formKey, beneficiaryHasValue },
              {
                common: t('inputValidation.name.common'),
                beneficiary: t('inputValidation.name.beneficiary'),
              },
            ),
          )}
          error={!!hasNameError}
          errorText={hasNameError?.message}
          label={label?.name}
          className="col-span-2"
          value={getNameValue(index)}
          onClick={onClickNameField}
          testId={{ input: trustAnalyserEditFormQa.name(formKey, index + 1) }}
          readOnly
          data-hj-suppress
        />
      )}

      {type === ApplicantTrustTypes.Organization ? (
        <FrankieTextField
          {...register(`${formKey}.${index}.abnOrAcn`)}
          value={watch(`${formKey}.${index}.abnOrAcn`)}
          label={label?.dateOrganisation}
          placeholder="e.g. 123456789"
          testId={{
            input: trustAnalyserEditFormQa.abnOrAcn(formKey, index + 1),
          }}
          data-hj-suppress
        />
      ) : (
        <FrankieTextField
          {...register(`${formKey}.${index}.dateOfBirth`)}
          value={watch(`${formKey}.${index}.dateOfBirth`)}
          type="date"
          label={label?.dateIndividual}
          max="9999-12-31"
          testId={{
            input: trustAnalyserEditFormQa.dateOfBirth(formKey, index + 1),
          }}
          data-hj-suppress
        />
      )}

      {type === ApplicantTrustTypes.Organization ? (
        <FrankieTextField
          {...register(`${formKey}.${index}.address`)}
          label={label?.addressOrganisation}
          className="col-span-2"
          placeholder={t('searchAddress')}
          testId={{
            input: trustAnalyserEditFormQa.address(formKey, index + 1),
          }}
          onClick={onClickAddressField}
          readOnly
          data-hj-suppress
        />
      ) : (
        <RenderSecondRowIndividualInput
          formKey={formKey}
          index={index}
          placeholder={t('searchAddress')}
          register={register}
          label={label}
          onClickAddressField={onClickAddressField}
        />
      )}
      {formKey === TrustFormTypes.Member &&
        type === ApplicantTrustTypes.Individual && (
          <FrankieTextField
            {...register(`member.${index}.address`)}
            label={label?.addressIndividual}
            className="col-span-3"
            placeholder={t('searchAddress')}
            testId={{
              input: trustAnalyserEditFormQa.address(formKey, index + 1),
            }}
            onClick={onClickAddressField}
            readOnly
            data-hj-suppress
          />
        )}
      {formKey === TrustFormTypes.UnitHolder && (
        <FrankieTextField
          {...register(
            `unitHolder.${index}.holdingCount`,
            holdingCountValidation({
              common: t('inputValidation.holdingCount.common'),
            }),
          )}
          error={!!hasNumberError}
          errorText={hasNumberError?.message}
          label={label?.holdingCount}
          className="col-span-3"
          onFocus={handleFocus}
          testId={{
            input: trustAnalyserEditFormQa.holdingCount(formKey, index + 1),
          }}
          type="number"
          data-hj-suppress
        />
      )}
    </div>
  )
}

function RenderFirstRowForm({
  formKey,
  register,
  handleFocus,
  errors,
}: {
  formKey: CommonTrustFormTypes
  register: UseFormRegister<ITrustDeedForm>
  handleFocus: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  errors: FieldErrors<ITrustDeedForm>
}) {
  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  if (formKey === TrustFormTypes.UnitHolder) {
    const hasNumberError = errors.trustGeneral?.totalUnits

    return (
      <div className="bg-tertiary-grey-50 rounded-md p-4 my-4">
        <h3 className="inline-block text-sm font-bold mb-4">
          {t('totalUnits')}
        </h3>
        <div className="grid grid-cols-3 gap-4">
          <FrankieTextField
            {...register(
              'trustGeneral.totalUnits',
              totalUnitsValidation({
                common: t('inputValidation.totalUnits.common'),
              }),
            )}
            error={!!hasNumberError}
            errorText={hasNumberError?.message}
            className="col-span-3"
            onFocus={handleFocus}
            testId={{
              input: trustAnalyserEditFormQa.totalUnits(
                TrustFormTypes.UnitHolder,
              ),
            }}
            type="number"
            data-hj-suppress
          />
        </div>
      </div>
    )
  }
  return null
}

// eslint-disable-next-line complexity
export function TrustCommonForm(props: TrustCommonFormProps) {
  const {
    formKey,
    index,
    title,
    isLastItem,
    onAdd,
    onRemove,
    tag,
    control,
    register,
    getValues,
    setValue,
    errors,
  } = props

  const [trustDetailFormType, setTrustDetailFormType] = React.useState(
    TrustDetailFormTypes.DEFAULT,
  )

  const ref = React.useRef<HTMLDivElement>(null)

  const onClickBack = (
    type: TrustDetailFormTypes,
    key: TrustFormTypes,
    idx: number,
  ) => {
    if (key === TrustFormTypes.GeneralBeneficiary) return

    const values = getValues()

    if (type === TrustDetailFormTypes.NAME) {
      const {
        givenName = '',
        middleName = '',
        familyName = '',
      } = values[key][idx]?.detail?.name || {}

      const fullName = getFullName({ givenName, middleName, familyName })
      setValue(`${key}.${index}.name`, fullName, { shouldValidate: !!fullName })
    }

    if (type === TrustDetailFormTypes.ADDRESS) {
      const address = values[key][idx]?.detail?.address || {}

      const fullAddress = formatAddress(address)
      setValue(`${key}.${index}.address`, fullAddress)
    }

    setTrustDetailFormType(TrustDetailFormTypes.DEFAULT)
  }

  /**
   * add event listener globally for key press on opening detail form,
   * to navigate back to the default form
   */
  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onClickBack(trustDetailFormType, formKey, index)
      }
    }
    if (trustDetailFormType !== TrustDetailFormTypes.DEFAULT) {
      window.addEventListener('keydown', handleKeyPress)
    }
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trustDetailFormType])

  const t = useI18n([APPLICANT_SUPPORTING_DOCUMENTS_KEY], {
    keys: applicantSupportingDocumentsEn,
  })

  const headerFooterText = useMemo(() => {
    if (formKey === TrustFormTypes.GeneralBeneficiary) {
      return t('generalBeneficiaries')
    }
    if (formKey === TrustFormTypes.SpecifiedBeneficiary) {
      return t('specifiedBeneficiary')
    }
    return t(formKey)
  }, [formKey, t])

  if (formKey === TrustFormTypes.GeneralBeneficiary)
    return (
      <TrustGeneralBeneficiaryForm
        control={control}
        formKey={TrustFormTypes.GeneralBeneficiary}
        index={index}
        isLastItem={isLastItem}
        onAdd={onAdd}
        onRemove={onRemove}
        register={register}
        title={title}
        tag={tag}
        errors={errors}
        shouldValidate={props.shouldValidate}
        watch={props.watch}
      />
    )

  const isDefaultForm = trustDetailFormType === TrustDetailFormTypes.DEFAULT

  const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    const inputElement = event.target

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault()
    }

    inputElement.addEventListener('wheel', handleWheel)

    // Remove the event listener when the input loses focus
    inputElement.onblur = () => {
      inputElement.removeEventListener('wheel', handleWheel)
    }
  }

  return (
    <>
      {index === 0 && title && (
        <div className="font-semibold text-lg mb-3">{title}</div>
      )}
      {index === 0 && (
        <RenderFirstRowForm
          formKey={formKey}
          register={register}
          handleFocus={handleFocus}
          errors={errors}
        />
      )}
      <div
        ref={ref}
        className={cx('rounded-md p-4 my-4', {
          'bg-tertiary-grey-50': isDefaultForm,
          'bg-primary-50 border-primary-800 border': !isDefaultForm,
        })}
      >
        <FormHeader
          number={index + 1}
          showRemove={!(isLastItem && index === 0)}
          onRemove={onRemove}
          text={headerFooterText}
          tag={tag}
          trustDetailFormType={trustDetailFormType}
          onClickBack={onClickBack}
          index={index}
          formKey={formKey}
          parentRef={ref}
        />

        <RenderFormContent
          {...props}
          trustDetailFormType={trustDetailFormType}
          setTrustDetailFormType={setTrustDetailFormType}
          handleFocus={handleFocus}
        />
      </div>
      <FormFooter
        onAdd={onAdd}
        showFooter={isLastItem}
        text={headerFooterText}
      />
    </>
  )
}
