import React, { useEffect, useRef, useState, forwardRef } from 'react'

import { FrankieTooltip } from 'frankify/src'

import { amlEntityCardQa } from 'features/individual-aml-result/qa/individual-aml-result.qa'

type Props = {
  value: string
  isUrl: boolean
}

type ContentProps = {
  value: string
  isUrl: boolean
}

type ContentRef = HTMLAnchorElement | HTMLParagraphElement

const ContentWithTruncation = forwardRef<ContentRef, ContentProps>(
  ({ value, isUrl }, ref) => {
    if (isUrl) {
      return (
        <a
          ref={ref as React.RefObject<HTMLAnchorElement>}
          data-qa={amlEntityCardQa.sourceUrl}
          className="underline text-primary-800 block w-[calc(100%-160px)] truncate"
          href={value}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      )
    }

    return (
      <p
        data-qa={amlEntityCardQa.sourceValue}
        ref={ref as React.RefObject<HTMLParagraphElement>}
        className="text-sm leading-5 text-tertiary-grey-700 w-[calc(100%-160px)] line-clamp-2"
      >
        {value}
      </p>
    )
  },
)

export function ValueWithTooltip({ value, isUrl }: Props) {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const textRef = useRef<HTMLParagraphElement>(null)

  const [isTextTruncated, setIsTextTruncated] = useState(false)

  useEffect(() => {
    const checkTruncation = () => {
      if (isUrl && linkRef.current) {
        // For links - check width only (single line)
        const isTruncated =
          linkRef.current.scrollWidth > linkRef.current.clientWidth
        setIsTextTruncated(isTruncated)
      } else if (!isUrl && textRef.current) {
        // For paragraphs - check height (multiple lines)
        const isTruncated =
          textRef.current.scrollHeight > textRef.current.clientHeight
        setIsTextTruncated(isTruncated)
      }
    }

    checkTruncation()
    window.addEventListener('resize', checkTruncation)
    return () => window.removeEventListener('resize', checkTruncation)
  }, [value, isUrl])

  return isTextTruncated ? (
    <FrankieTooltip body={value}>
      <ContentWithTruncation
        ref={isUrl ? linkRef : textRef}
        value={value}
        isUrl={isUrl}
      />
    </FrankieTooltip>
  ) : (
    <ContentWithTruncation
      ref={isUrl ? linkRef : textRef}
      value={value}
      isUrl={isUrl}
    />
  )
}
